<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="renewalOrder-container">
    <div class="title-container"><div>订单中心</div></div>
    <div v-if="dataAll" class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">累计订单总数</div>
        <div class="item-black">{{ dataAll.totalNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <el-tooltip class="item" effect="dark" content="统计处于服务时间内订单及待服务的订单数量" placement="bottom">
        <div class="item-title">有效订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <div @click="() => { collect(
                'validOrderNum',
                '有效订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  { name: '订单状态', type: 'select', modelKey: 'orderState',selOpt: [{id: 'process',name: '续签预警'},{id: 'setFail',name: '续签失败'},{id: 'complete',name: '续签成功'},{id: 'servicing',name: '正在服务'},{id: 'fail',name: '续签超时'},{id: 'setDelete',name: '订单作废'}] },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                ],
              )}" class="item-num finger">{{ dataAll.validOrderNum || "0" }}</div>
      </div>
      <div class="Summary-item SummaryItem-border">
        <el-tooltip class="item" effect="dark" content="订单服务时间结束，财务未完成确认收款的订单为历史欠款订单" placement="bottom">
        <div class="item-title">历史欠款订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <!-- { name: '收款状态', type: 'select', modelKey: 'financialStatus',selOpt: [{id: 'pending',name: '待销售催款'},{id: 'confirming',name: '待财务确认'},{id: 'complete',name: '交易完成'}] }, -->
        <div @click="() => { collect(
                'historyUnpaidOrderNum',
                '历史欠款订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  { name: '收款状态', type: 'select', modelKey: 'financialStatus',selOpt: [{id: 'pending',name: '待销售催款'},{id: 'confirming',name: '待财务确认'},{id: 'complete',name: '交易完成'}] },
                  { name: '订单收款方式', type: 'select', modelKey: 'paymentMethods',selOpt: [{id: 0,name: '前置收款'},{id: 1,name: '前置服务'},{id: 2,name: '免费试用'},] },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  { name: '收款状态', colProp: ['financialStatus'] },
                  { name: '订单收款方式', colProp: ['paymentMethods'] },
                  { name: '未收金额', colProp: ['uncollectionAmount'] },
                ],
              )}" class="item-num finger">{{ dataAll.historyUnpaidOrderNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">流失客户数</div>
        <div @click="() => { collect(
                'failNum',
                '流失客户数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  { name: '订单状态', colProp: ['orderState'] },
                ],
              )}" class="item-num finger">{{ dataAll.customerFailNum || "0" }}</div>
      </div>
      <div class="Summary-item">
        <div class="item-title">客户续签滑单数</div>
        <div @click="() => { collect(
                'failNum',
                '客户续签滑单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  { name: '订单状态', colProp: ['orderState'] },
                ],
              )}" class="item-num finger">{{ dataAll.failNum || "0" }}</div>
      </div>
      <!-- <div class="Summary-item SummaryItem-border">
        <el-tooltip class="item" effect="dark" content="【说明】计算续签成功的客户比率
【公式】客户续签成功率=续签成功的客户数/成交过订单的总客户数" placement="bottom">
        <div class="item-title">客户当前留存率<img src="../../assets/images/question.png" class="tit-img"/></div>
        </el-tooltip>
        <div class="item-black">{{ dataAll.customerSuccessRate || "0%" }}</div>
      </div> -->
      <div class="Summary-item">
        <el-tooltip class="item" effect="dark" content="统计因续签失败而停止服务的店铺数量" placement="bottom">
        <div class="item-title">流失店铺数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <div @click="() => { collect(
                'shopFailNum',
                '流失店铺数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                ],
                [
                  { name: '店铺名称', colProp: ['shopName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  { name: '店铺服务状态', colProp: ['orderState'] },
                ],
              )}" class="item-num finger">{{ dataAll.shopFailNum || "0" }}</div>
      </div>
      <!-- <div class="Summary-item">
        <el-tooltip class="item" effect="dark" content="【说明】计算店铺续签成功的比率
【公式】续签成功率=续签成功的店铺数/店铺总数" placement="bottom">
        <div class="item-title">店铺当前留存率<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
        </el-tooltip>
        <div class="item-black">{{ dataAll.shopSuccessRate || "0" }}</div>
      </div> -->
      
    </div>
    <div :class="{'isMore-height': isMore,'isMore-height0': !isMore}">
      <div class="common-Summary-container">
        <div class="Summary-item" style="align-items: center;">
          <el-select style="width: 80px;margin-left: 32px" v-model="topDate" @change="changeRadio">
            <el-option label="今日" :value="1"></el-option>
            <el-option label="昨日" :value="2"></el-option>
            <el-option label="本周" :value="3"></el-option>
            <el-option label="本月" :value="5"></el-option>
          </el-select>
        </div>
        <div class="Summary-item">
          <div class="item-title">新增订单数</div>
          <div @click="() => { collect(
                'addOrderNum',
                '新增订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  { name: '订单状态', type: 'select', modelKey: 'orderState',selOpt: [{id: 'process',name: '续签预警'},{id: 'setFail',name: '续签失败'},{id: 'complete',name: '续签成功'},{id: 'servicing',name: '正在服务'},{id: 'fail',name: '续签超时'},{id: 'setDelete',name: '订单作废'}] },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                ]
              )}" class="item-num finger">{{ dateRange.addOrderNum || "0" }}</div>
        </div>
        <div class="Summary-item">
          <el-tooltip class="item" effect="dark" content="统计一定时间内新增但未完成付款的订单数量" placement="bottom">
          <div class="item-title">新增未完成付款订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                'unPaidOrderNum',
                '新增未完成付款订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  { name: '订单状态', type: 'select', modelKey: 'orderState',selOpt: [{id: 'process',name: '续签预警'},{id: 'setFail',name: '续签失败'},{id: 'complete',name: '续签成功'},{id: 'servicing',name: '正在服务'},{id: 'fail',name: '续签超时'},{id: 'setDelete',name: '订单作废'}] },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                ]
              )}" class="item-num finger">{{ dateRange.unPaidOrderNum || "0" }}</div>
        </div>
        <div class="Summary-item">
          <el-tooltip class="item" effect="dark" content="统计一定时间内新增已完成付款的订单数量" placement="bottom">
          <div class="item-title">新增已完成付款订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                'paidOrderNum',
                '新增已完成付款订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  { name: '订单状态', type: 'select', modelKey: 'orderState',selOpt: [{id: 'process',name: '续签预警'},{id: 'setFail',name: '续签失败'},{id: 'complete',name: '续签成功'},{id: 'servicing',name: '正在服务'},{id: 'fail',name: '续签超时'},{id: 'setDelete',name: '订单作废'}] },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                ]
              )}" class="item-num finger">{{ dateRange.paidOrderNum || "0" }}</div>
        </div>
        <div class="Summary-item">
          <el-tooltip class="item" effect="dark" content="统计一定时间内续签成功的订单总数" placement="bottom">
          <div class="item-title">续签订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                'completeOrderNum',
                '续签订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                ]
              )}" class="item-num finger">{{ dateRange.completeOrderNum || "0" }}</div>
        </div>
        <div class="Summary-item SummaryItem-border">
          <el-tooltip class="item" effect="dark" content="统计续签失败的订单数量" placement="bottom">
          <div class="item-title">流失订单数<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                'failOrderNum',
                '流失订单数',
                [
                  { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                ],
                [
                  { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                ]
              )}" class="item-num finger">{{ dateRange.failOrderNum || "0" }}</div>
        </div>
        <div class="Summary-item" style="align-items: center;">
          <el-date-picker class="topHeadDate" v-model="params.reportDate" @change="testDateFun" value-format="yyyy-MM-dd" type="month" format="yyyy-MM" placeholder="月份"></el-date-picker>
        </div>
        <div class="Summary-item">
          <div class="item-title">客户续签成功率</div>
          <div class="item-black">{{ dataAll.customerRenewalRate || "0%" }}</div>
        </div>
      </div>
      <div class="common-Summary-container">
        <div class="Summary-item">
          <el-tooltip class="item" effect="dark" content="统计剩余服务天数不足7天的订单数量" placement="bottom">
          <div class="item-title">7天内到期<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                  'sevenDays',
                  '7天内到期',
                  [
                    { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  ]
                )}" class="item-num finger">{{ dataAll.sevenDays || "0" }}</div>
        </div>
        <div class="Summary-item">
          <el-tooltip class="item" effect="dark" content="统计剩余服务天数不足3天的订单数量" placement="bottom">
          <div class="item-title">3天内到期<img
              src="../../assets/images/question.png"
              class="tit-img"
            /></div>
          </el-tooltip>
          <div @click="() => { collect(
                  'threeDays',
                  '3天内到期',
                  [
                    { name: '订单编号', type: 'input', modelKey: 'orderNoAndName' },
                  { name: '所属客户', type: 'input', modelKey: 'customerName' },
                  { name: '所属店铺', type: 'input', modelKey: 'shopName' },
                  ],
                  [
                    { name: '订单编号', colProp: ['orderNo'] },
                  { name: '订单名称', colProp: ['orderName'] },
                  { name: '所属客户', colProp: ['customerName'] },
                  { name: '所属店铺', colProp: ['shopName'] },
                  { name: '订单状态', colProp: ['orderState'] },
                  { name: '服务时间', colProp: ['startTime','endTime'] },
                  ]
                )}" class="item-num finger">{{ dataAll.threeDays || "0" }}</div>
        </div>
        <div class="Summary-item"></div>
        <div class="Summary-item"></div>
        <div class="Summary-item"></div>
        <div class="Summary-item"></div>
        <div class="Summary-item"></div>
      </div>
    </div>
    
    <div class="common-isMore-btn" @click="isMore = !isMore">{{isMore ? '收起' : '查看更多'}}<i v-if="!isMore" class="el-icon-caret-bottom"></i><i v-if="isMore" class="el-icon-caret-top"></i></div>
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>订单名称/编号:</span>
        <el-input
          class="common-screen-input"
          placeholder="订单名称/编号"
          v-model="params.keyword"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属店铺:</span>
        <el-select class="common-screen-input" v-model="params.shopId" placeholder="请搜索店铺" @change="commonFun" filterable clearable remote :remote-method="(val)=>{remoteMethod(val, 'shopList')}">
          <el-option v-for="item in shopList" :key="item.id" :label="item.shop_name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>所属客户:</span>
        <el-select
          class="common-screen-input"
          v-model="params.customerId"
          placeholder="请搜索客户"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="(val)=>{remoteMethod(val, 'custList')}"
        >
          <el-option
            v-for="item in custList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单类型:</span>
        <el-select
          class="common-screen-input"
          v-model="params.orderType"
          placeholder="业务类型"
          @change="commonFun"
          clearable
          filterable
        >
          <el-option
            v-for="item in orderTypeOption"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>业务类型:</span>
        <el-select
          class="common-screen-input"
          v-model="params.type"
          placeholder="业务类型"
          @change="commonFun"
          clearable
          filterable
        >
          <el-option
            v-for="item in custDict['12']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>服务状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.status"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option key="complete" label="续签成功" value="complete"> </el-option>
          <el-option key="process" label="续签预警" value="process"> </el-option>
          <el-option key="setFail" label="续签失败" value="setFail"> </el-option>
          <el-option key="fail" label="续签超时" value="fail"> </el-option>
          <el-option key="servicing" label="正在服务" value="servicing"></el-option>
          <el-option key="setDelete" label="订单作废" value="setDelete"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>服务阶段:</span>
        <el-select
          class="common-screen-input"
          v-model="params.orderStep"
          placeholder="请选择"
          @change="commonFun"
          filterable
          clearable
        >
          <el-option v-for="item in custDict['5']" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>收款状态:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun"
          v-model="params.financialStatus"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in incomeStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单收款方式:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun"
          v-model="params.paymentMethods"
          placeholder="请选择"
          filterable
        >
          <el-option
            v-for="item in payType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>订单负责人:</span>
        <el-input
          class="common-screen-input"
          placeholder="请输入"
          v-model="params.createName"
        ></el-input>
      </div>
      <div class="common-input-container">
        <common-date @commonGetDate="commonGetDate" ref="commonReset"></common-date>
      </div>
      <div class="common-input-container">
        <el-button class="common-screen-btn" type="primary" @click="() => commonFun()">查 询</el-button>
        <el-button class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>
      </div>
    </div>
    <div class="gap"></div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-if="rolesName=='公司管理员'||rolesName=='销售主管'||rolesName=='超级管理员'" label="所有客户订单" name="0">
        <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      class="common-table"
      :data="tableData"
    >
      <el-table-column label="订单编号" :formatter="tableColumn" width="180" fixed="left">
        <template slot-scope="scope">
          <div class="newLable">
            <div class="tableBtn" @click="() => getOrderDetailOne(scope.row)"> {{ scope.row.orderNo || '--' }} </div>
            <div v-if="!overstepDateNum(scope.row.createTime, 7)" class="common-tableStatus-red lable">NEW</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderName"
        label="订单名称"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="customerOrderTypeName"
        label="订单类型"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="customerName"
        label="所属客户"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="typeName"
        label="业务类型"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="shopNames"
        label="所属店铺"
        width="140px"
      >
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.shopNames"
            class="item"
            effect="dark"
            :content="scope.row.shopNames"
            placement="bottom"
          >
            <div style="white-space: pre-line">
              {{ commonLineFeed(scope.row.shopNames) }}
            </div>
          </el-tooltip>
          <div v-else style="white-space: pre-line">
            {{ commonLineFeed(scope.row.shopNames) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="产能金额" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.salesSalary || '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderStep"
        label="服务阶段"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column label="服务时间" width="120px">
        <template slot-scope="scope">
          <div style="white-space: pre-line">
            {{
              `${DateTransform(scope.row.startDate)}\n${DateTransform(scope.row.endDate)}`
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="120px">
        <template slot-scope="scope">
          <div>
            <div v-if="scope.row.status == 'process'" class="common-tableStatus-red-bgc">续签预警</div>
            <div v-if="scope.row.status == 'setFail'" class="common-tableStatus-grey-bgc">续签失败</div>
            <div v-if="scope.row.status == 'complete'" class="common-tableStatus-blue-bgc">续签成功</div>
            <div v-if="scope.row.status == 'servicing'" class="common-tableStatus-green-bgc">正在服务</div>
            <div v-if="scope.row.status == 'fail'" class="common-tableStatus-orange-bgc">续签超时</div>
            <div v-if="scope.row.status == 'setDelete'" class="common-tableStatus-grey-bgc">订单作废</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收款状态" width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.financialStatus">
            <div v-if="scope.row.financialStatus == 'pending'" style="color: #F98787;">待销售催款</div>
            <div v-if="scope.row.financialStatus == 'confirming'" style="color: #F09009;">待财务确认</div>
            <div v-if="scope.row.financialStatus == 'complete'" style="color: #1890FF;">交易完成</div>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="订单收款方式" width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.paymentMethods">
            <div v-if="scope.row.paymentMethods == '前置收款'" >
              <span class="common-label1">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>
                前置收款
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '前置服务'">
              <span class="common-label2">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>前置服务
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '免费试用'">
              <span class="common-label3">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>免费试用
              </span>
              </div>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="收款比例" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.collectionRate ? (scope.row.collectionRate).toFixed(2) : '0.00'}} %</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="originalPrice"
        label="订单原始价格"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="differencePrice"
        label="订单调价价格"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column prop="price" label="订单应收金额" :formatter="tableColumn" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.price ? (scope.row.price).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="collectionAmount" label="实收金额" :formatter="tableColumn" width="120px" >
        <template slot-scope="scope">
            <div>{{ scope.row.collectionAmount ? (scope.row.collectionAmount).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="uncollectionAmount" label="未收金额" :formatter="tableColumn" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.uncollectionAmount ? (scope.row.uncollectionAmount).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="price"
        label="销售负责人"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column> -->
      <el-table-column
        prop="createName"
        label="订单负责人"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        :formatter="tableColumn"
        width="160px"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column label="操作" width="180px" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 'complete'" type="text" size="small" @click="() => RenewalParams(scope.row, 'current')">一键同步</el-button>
          <span style="color: #ccc;margin-right: 16px" v-if="scope.row.status != 'complete'">一键同步</span>
          <el-button type="text" size="small" @click="() => addShop(scope.row)">新增店铺</el-button>
        </template>
      </el-table-column> -->
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="我的客户订单" name="1">
        <el-table
      ref="multipleTable"
      @selection-change="handleSelectionChange"
      class="common-table"
      :data="tableData"
    >
      <el-table-column label="订单编号" :formatter="tableColumn" width="180" fixed="left">
        <template slot-scope="scope">
          <div class="newLable">
            <div class="tableBtn" @click="() => getOrderDetailOne(scope.row)"> {{ scope.row.orderNo || '--' }} </div>
            <div v-if="!overstepDateNum(scope.row.createTime, 7)" class="common-tableStatus-red lable">NEW</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderName"
        label="订单名称"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="customerOrderTypeName"
        label="订单类型"
        :formatter="tableColumn"
        width="140px"
      ></el-table-column>
      <el-table-column
        prop="customerName"
        label="所属客户"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="typeName"
        label="业务类型"
        :formatter="tableColumn"
        width="100px"
      ></el-table-column>
      <el-table-column
        prop="shopNames"
        label="所属店铺"
        width="140px"
      >
        <template slot-scope="scope">
          <el-tooltip
            v-if="scope.row.shopNames"
            class="item"
            effect="dark"
            :content="scope.row.shopNames"
            placement="bottom"
          >
            <div style="white-space: pre-line">
              {{ commonLineFeed(scope.row.shopNames) }}
            </div>
          </el-tooltip>
          <div v-else style="white-space: pre-line">
            {{ commonLineFeed(scope.row.shopNames) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="orderStep"
        label="服务阶段"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column label="服务时间" width="120px">
        <template slot-scope="scope">
          <div style="white-space: pre-line">
            {{
              `${DateTransform(scope.row.startDate)}\n${DateTransform(scope.row.endDate)}`
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="订单状态" width="120px">
        <template slot-scope="scope">
          <div>
            <div v-if="scope.row.status == 'process'" class="common-tableStatus-red-bgc">续签预警</div>
            <div v-if="scope.row.status == 'setFail'" class="common-tableStatus-grey-bgc">续签失败</div>
            <div v-if="scope.row.status == 'complete'" class="common-tableStatus-blue-bgc">续签成功</div>
            <div v-if="scope.row.status == 'servicing'" class="common-tableStatus-green-bgc">正在服务</div>
            <div v-if="scope.row.status == 'fail'" class="common-tableStatus-orange-bgc">续签超时</div>
            <div v-if="scope.row.status == 'setDelete'" class="common-tableStatus-grey-bgc">订单作废</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="收款状态" width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.financialStatus">
            <div v-if="scope.row.financialStatus == 'pending'" style="color: #F98787;">待销售催款</div>
            <div v-if="scope.row.financialStatus == 'confirming'" style="color: #F09009;">待财务确认</div>
            <div v-if="scope.row.financialStatus == 'complete'" style="color: #1890FF;">交易完成</div>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="订单收款方式" width="120px">
        <template slot-scope="scope">
          <div v-if="scope.row.paymentMethods">
            <div v-if="scope.row.paymentMethods == '前置收款'" >
              <span class="label1">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>
                前置收款
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '前置服务'">
              <span class="label2">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>前置服务
              </span>
              </div>
            <div v-if="scope.row.paymentMethods == '免费试用'">
              <span class="label3">
                <i class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "></i>免费试用
              </span>
              </div>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column label="收款比例" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.collectionRate ? (scope.row.collectionRate).toFixed(2) : '0.00'}} %</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="originalPrice"
        label="订单原始价格"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column
        prop="differencePrice"
        label="订单调价价格"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column>
      <el-table-column prop="price" label="订单应收金额" :formatter="tableColumn" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.price ? (scope.row.price).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="collectionAmount" label="实收金额" :formatter="tableColumn" width="120px" >
        <template slot-scope="scope">
            <div>{{ scope.row.collectionAmount ? (scope.row.collectionAmount).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="uncollectionAmount" label="未收金额" :formatter="tableColumn" width="120px">
        <template slot-scope="scope">
            <div>{{ scope.row.uncollectionAmount ? (scope.row.uncollectionAmount).toFixed(2) : '--'}}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="price"
        label="销售负责人"
        :formatter="tableColumn"
        width="120px"
      ></el-table-column> -->
      <el-table-column
        prop="createName"
        label="订单负责人"
        :formatter="tableColumn"
        width="160px"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        :formatter="tableColumn"
        width="180px"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="备注"
        :formatter="tableColumn"
        width="160px"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column label="操作" width="180px" fixed="right">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 'complete'" type="text" size="small" @click="() => RenewalParams(scope.row, 'current')">一键同步</el-button>
          <span style="color: #ccc;margin-right: 16px" v-if="scope.row.status != 'complete'">一键同步</span>
          <el-button type="text" size="small" @click="() => addShop(scope.row)">新增店铺</el-button>
        </template>
      </el-table-column> -->
        </el-table>
        <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
        </el-pagination>
      </el-tab-pane>
    </el-tabs>
    <!-- <el-button v-if="!roles.includes('续签') && !roles.includes('销售')" class="common-screen-btn" type="primary" @click="() => {assignMore()}">批量指派</el-button> -->
    
    <el-dialog
      title="指派分公司"
      :append-to-body="true"
      :visible.sync="centerDialogVisible"
      width="520px"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="分公司负责人" prop="serviceId">
            <el-select
              class="dialog-input"
              v-model="ruleForm.serviceId"
              placeholder="请选择负责人"
              filterable
            >
              <el-option
                v-for="item in comPeopleList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="() => orderAppoint('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <order-detail
      ref="saleLead"
      :drawer="drawer"
      :comPeopleList="comPeopleList"
      :tableRow="tableRow"
      :adminInfo="adminInfo"
      :OrderInfo="OrderInfo"
      :isComfirmFlow="isComfirmFlow"
      :platList="platList"
      :orderTaskList="orderTaskList"
      :feedbackList="feedbackList"
      :nextStage="nextStage"
      :userList="userList"
      :fileList="fileList"
      :custList="custList"
      :custDict="custDict"
      :payTypeName="payTypeName"
      :residuePrice="residuePrice"
      @handleClose="handleClose"
      @getOrderDetail="getOrderDetail"
      @RenewalParams="RenewalParams"
      @loadFun="loadFun"
      :loading="loading"
      :title="'查看订单'"
    ></order-detail>
    <shop-order-add
      ref="cusListAdd"
      :tableRow="tableRow"
      :drawer="drawerShop"
      :platList="platList"
      :finishedList="custList"
      :userList="userList"
      @handleClose="handleCloseShop"
    ></shop-order-add>
    <el-dialog
      title="批量指派"
      :append-to-body="true"
      :visible.sync="assignVisible"
      width="520px"
    >
      <div>
        <el-form :model="assignForm" label-width="100px" class="demo-ruleForm">
          <div class="items-conteiner">
            <div class="itemOrder" v-for="(item, index) in multipleTable" :key="index">
              {{ item.orderName }}
              |
              <span class="deleteItem" @click="() => deleteItem(item)"> × </span>
            </div>
          </div>
          <el-form-item label="添加指派人">
            <el-select
              class="common-screen-input_100"
              v-model="assignForm.adminIds"
              filterable
              multiple
              placeholder="请选择负责人"
            >
              <el-option
                v-for="item in assignSelect"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <div class="items-conteiner">
            <div class="itemOrder" v-for="(item, index) in multipleTable" :key="index">
              {{ item.orderName }}
            </div>
          </div> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              submitAssign();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="续签成功"
      :append-to-body="true"
      :visible.sync="RenewalUpdate"
      width="520px"
    >
      <div>
        <div v-if="serviceTimeTable" class="detail-top">
          <div class="item-conteiner">
            <span>续签订单id: </span>
            <span> {{ (RenewalSuccDetail && RenewalSuccDetail.orderId) || "--" }}</span>
          </div>
          <div class="item-conteiner">
            <span>服务时间: </span>
            <span class="item-blue">
              {{
                (serviceTimeTable.orderInfo && serviceTimeTable.orderInfo.serviceTime) ||
                "--"
              }}</span
            >
          </div>
          <div class="item-conteiner">
            <span>服务金额: </span>
            <span>
              {{
                (serviceTimeTable.orderInfo &&
                  serviceTimeTable.orderInfo.price &&
                  serviceTimeTable.orderInfo.price + "元") ||
                "0"
              }}</span
            >
          </div>
          <div class="item-conteiner">
            <span>本次指派: </span>
            <span class="item-blue">
              {{
                (serviceTimeTable.orderInfo && serviceTimeTable.orderInfo.toUser) || "--"
              }}</span
            >
          </div>
        </div>
        <div class="order">
          <div class="order-tit">
            此前订单中含 {{
              serviceTimeTable && serviceTimeTable.list && serviceTimeTable.list.length
            }} 家店铺，是否自动更新本次订单续费服务时间及负责人
          </div>
          <el-table
            @selection-change="dialogSelectionChange"
            header-row-class-name="headGray"
            style="width: 100%"
            :data="serviceTimeTable && serviceTimeTable.list"
          >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column
              prop="shop_name"
              label="店铺名称"
              :formatter="tableColumn"
            ></el-table-column>
            <el-table-column label="历史服务时间">
              <template slot-scope="scope">
                <div>{{ scope.row.start_time }}~{{ scope.row.end_time }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="businese_user"
              label="运营负责人"
              :formatter="tableColumn"
            ></el-table-column>
          </el-table>
        </div>
        <div style="position: relative">
          <div class="updateBtn" @click="() => synchronizeTime()">一键更新</div>
          <div class="routeUpdate" @click="() => { openWin('/customer/company-service-shop-list') }"> 手动更新 </div>
        </div>
      </div>
    </el-dialog>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="getOrderReportList"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import OrderDetail from "./children/renewalOrderDetail.vue";
import ShopOrderAdd from "./children/shopOrderAdd.vue";
import {
  getRenewalList,
  assignRenewal,
  getOrderDetail,
  orderAppoint,
  getPlatList,
  orderShopServiceTime,
  synchronizeTime,
  selectOrderHeadNum,
  getOrderReportList
} from "../../service/customer.js";
import { getUserList } from "../../service/manage.js";
import { commonDict, getShopList, getCustList, getRoleByType } from "../../service/common";
import { tableColumn, Config, DateTransform, commonLineFeed, overstepDateNum,getSectionDate,getLatelyDay } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate.vue"
// import { report } from "../../service/home.js"
import CommonSumDialog from "../../components/common/commonSumDialog.vue"
export default {
  components: { Breadcrumb, OrderDetail, ShopOrderAdd, CommonDate,CommonSumDialog },
  // props: ["createFlag"],
  data() {
    return {
      isMore: false, // 查看更多
      overstepDateNum,
      fileList: [],
      commonLineFeed,
      DateTransform,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company,
      roles: this.$sto.get(Config.constants.userInfo).roles,
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      assignVisible: false,
      RenewalUpdate: false, // 续签成功弹窗
      RenewalSuccDetail: {}, // 续签成功弹窗详情
      serviceTimeTable: [],
      assignForm: {}, // 批量指派提交数据
      tableRow: "",
      adminInfo: "",
      OrderInfo: "",
      isComfirmFlow: '', // 订单调价-判断是否需要审批
      orderTaskList: "",
      feedbackList: "",
      nextStage: "",
      tableColumn,
      loading: false,
      platList: [],
      userList: [],
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "营销", isLink: false },
        { title: "运营", isLink: false },
        { title: "订单中心", isLink: false },
      ],
      tableRowId: "", // 客户id用于编辑/查看
      centerDialogVisible: false, // 修改密码员工弹窗
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      assignPeople: [],
      comPeopleList: [],
      assignSelect: [],
      custDict: {"5": [], "12": []},
      custList: [], // 所属客户下拉
      shopList: [], // 所属店铺下拉
      drawerShop: false, // 新增店铺弹窗
      params: {
        reportDate: '',
        keyword: '',
        page: 1,
        pageSize: 10,
      },
      ruleForm: {
        // 修改密码
      },
      rules: {
        serviceId: [{ required: true, message: "请选择负责人", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      roleSelect: "",
      incomeStatus: [
        {
          value: "pending",
          label: "待销售催款",
        },
        {
          value: "confirming",
          label: "待财务确认",
        },
        {
          value: "complete",
          label: "交易完成",
        },
      ],
      tableData: [],
      dataAll: "",
      multipleTable: [], // 批量指派多选
      dialogMultipleTable: [], // 一键更新多选
      // 汇总弹窗所需数据
      commonSumVisible: false,
      getOrderReportList, // 列表接口方法
      topDate: 1,
      dateRange: {}, //筛选日期数据
      params2: {
        startDate: DateTransform(new Date()),
        endDate: DateTransform(new Date()),
      },
      payType: [
        {id: 0,name: '前置收款'},
        {id: 1,name: '前置服务'},
        {id: 2,name: '免费试用'},
      ],
      activeName: '0',
      rolesName: this.$sto.get(Config.constants.userInfo).roles[0],
      payTypeName: "",
      residuePrice: '', // 订单剩余金额
      orderTypeOption: [
        {id: '2',name: '续签订单'},
        {id: '1',name: '新签订单'},
      ]
    };
  },
  mounted() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    
    if(this.rolesName=='公司管理员'||this.rolesName=='销售主管'||this.rolesName=='超级管理员'){
      this.getRenewalList();
    } else {
      this.activeName = '1'
      this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
      this.getRenewalList();
    }
    if(this.$route.query.orderNo && this.$route.query.orderId) {
      this.params.keyword = this.$route.query.orderNo + ''
      this.isFromFun()
    }
    this.getPlatList();
    this.commonDict(5);
    this.commonDict(12);
    this.getCustList();
    this.getShopList();
    this.getRoleByType(1);
    this.getRoleByType(2);
    this.getRoleByType(3);
    this.getUserList();
    this.getSelectOrderHeadNum()
  },
  methods: {
    testDateFun() {
      this.getRenewalList()
    },
    async isFromFun() { // 客户页面跳转过来
      let params = { ...this.params };
      let fromRow
      // params.orderType = 1
      let { data } = await getRenewalList(params);
      fromRow = data.listData.list[0]
      if (fromRow) {
        this.getOrderDetailOne(fromRow)
      }
      
    },
    // 数据汇总弹窗start
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) { // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      if(this.activeName == 0){
        this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId,"","","",[this.params2.startDate,this.params2.endDate],"","","","","",this.activeName);
      }else{
        let adminId = this.$sto.get(Config.constants.userInfo).user_id
        if(adminId){
          this.$refs.commonSumDialog.getAllData(type, name, screen, arr, this.params.companyId,"","","",[this.params2.startDate,this.params2.endDate],"","","","","",this.activeName);
        }
        
      }
      
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗end
    commonGetDate (startTime, endTime) {
      this.params.startDate = startTime
      this.params.endDate = endTime
      this.getRenewalList()
    },
    remoteMethod (val, name) {
      if (name == 'shopList') {
        this.getShopList(val)
      } else if (name == 'custList') {
        this.getCustList(val)
      }
    },
    loadFun(value) {
      this.loading = value;
    },
    openWin(url) {
      window.open(`${window.location.origin}${url}`);
    },
    deleteItem(row) {
      let multipleTable = [...this.multipleTable];
      if (multipleTable.length <= 1) {
        this.$message.error("至少一条订单");
        return;
      }
      multipleTable = multipleTable.filter((item) => {
        return item.id != row.id;
      });
      this.$refs.multipleTable.toggleRowSelection(row, false);
      this.multipleTable = multipleTable;
    },
    async synchronizeTime() {
      // 一键更新
      let dialogMultipleTable = [...this.dialogMultipleTable]
      let newArr = []
      if(dialogMultipleTable.length) {
        dialogMultipleTable.map((item)=>{
          newArr.push(item.id)
        })
      } else {
        return this.$message.error('请先选择店铺')
      }
      await synchronizeTime({shopIds: newArr.join(',')}, this.RenewalSuccDetail.orderId);
      this.$message.success("更新成功");
      this.RenewalUpdate = false;
    },
    async orderShopServiceTime(id) {
      // 续签成功服务时间-更新
      let resData = (await orderShopServiceTime({}, id)).data;
      this.serviceTimeTable = resData;
    },
    RenewalParams(info, type) {
      let orderId = info.orderId || info.renewalOrderId;
      if (type == "current") {
        this.RenewalSuccDetail = {
          orderId: info.renewalOrderId,
          serviceTime: info.sericeTime,
          orderPrice: info.price,
          userName: info.toName,
        };
      } else {
        this.RenewalSuccDetail = info;
      }
      this.orderShopServiceTime(orderId);
      this.RenewalUpdate = true;
    },
    async submitAssign() {
      // 批量指派提交
      let assignForm = { ...this.assignForm };
      let multipleTable = [...this.multipleTable];
      if (assignForm.adminIds && assignForm.adminIds.length) {
        assignForm.adminIds = assignForm.adminIds.join(",");
      } else {
        this.$message.error("请选择指派人");
      }
      let newArr = [];
      multipleTable.map((item) => {
        newArr.push(item.id);
      });
      assignForm.orderIds = newArr.join(",");
      assignForm.companyId = this.rolesCompany[0].id;
      let res = await assignRenewal(assignForm);
      if (res.code == "200") {
        this.$message.success("指派成功");
        this.getRenewalList();
        this.multipleTable = [];
        this.assignVisible = false;
        this.assignForm.adminIds = [];
      }
    },
    assignMore() {
      // 批量指派弹窗
      let multipleTable = [...this.multipleTable];
      if (!multipleTable || !multipleTable.length) {
        this.$message.success("请先选择");
        return false;
      }
      this.assignVisible = true;
    },
    handleSelectionChange(row) {
      // 批量指派
      this.multipleTable = row;
    },
    dialogSelectionChange(row) {
      // 续签更新
      this.dialogMultipleTable = row;
    },
    async getUserList() {
      // 商务负责下拉，暂用员工列表
      let resData = (await getUserList({ pageNum: -1, companyId: this.companyId })).data;
      this.userList = resData;
    },
    async getPlatList() {
      // 所属平台/类目
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.getRenewalList();
      this.getShopList()
      this.getCustList()
    },
    dialogAppoint(row) {
      let params = { ...this.ruleForm };
      this.ruleForm = Object.assign(row, params);
      this.ruleForm.customerId = row.customer_id;
      this.centerDialogVisible = true;
    },
    async orderAppoint(formName) {
      let params = { ...this.ruleForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await orderAppoint(params);
          this.$message.success("指派成功");
          this.centerDialogVisible = false;
          this.getRenewalList();
        } else {
          return false;
        }
      });
    },
    async getRoleByType(id) {
      let resData = (await getRoleByType({companyId: this.companyId,}, id)).data;
      if (id == 1) {
        this.assignPeople = resData;
      } else if (id == 2) {
        this.comPeopleList = resData;
      } else if (id == 3) {
        this.assignSelect = resData;
      }
    },
    getOrderDetailOne(row) { // 第一次点击弹窗
      this.$refs["saleLead"].orderShopList('shopTableData', row.id)
      this.$refs["saleLead"].orderChangePrice(row.id) // 订单调价列表
      this.$refs["saleLead"].orderShopList('shopSelect', row.id)
      // this.$refs["saleLead"].workLog(row.id)
      this.$refs["saleLead"].orderFlowList(row.id)
      this.$refs["saleLead"].orderNoticeList(this.companyId)
      this.getUserList()
      this.getOrderDetail(row)
    },
    async getOrderDetail(row) {
      // 获取订单详情
      this.drawer = true;
      this.loading = true;
      let resData = (await getOrderDetail({ orderId: row.id })).data;
      this.payTypeName = resData.customerOrderDetailVo.paymentMethods
      if (resData && resData.customerOrderStageList) {
        let nextStage = true;
        let stageTask = [...resData.customerOrderStageList];
        stageTask.map((item) => {
          // if (row.orderStepStatus == item.step && item.groupTaskId > 0) {
          if (resData.customerOrderDetailVo.orderStep == item.step) {
            if (item.orderStageState == "0") {
              nextStage = false;
            }
          }
        });
        this.nextStage = nextStage;
      }
      this.tableRow = row;
      this.residuePrice = resData.residuePrice || 0
      this.adminInfo = resData.admin || {};
      this.OrderInfo = resData.customerOrderDetailVo || {};
      this.isComfirmFlow = resData.isComfirmFlow || '';
      if(resData && resData.customerOrderDetailVo) {
        let filedIdsArr = resData.customerOrderDetailVo.filedIds ? resData.customerOrderDetailVo.filedIds.split(',') : []
        let filedNameArr = resData.customerOrderDetailVo.filedName ? resData.customerOrderDetailVo.filedName.split(',') : []
        let filedUrlArr = resData.customerOrderDetailVo.filedUrl ? resData.customerOrderDetailVo.filedUrl.split(',') : []
        let newArrFile = []
        filedIdsArr.map((item, index)=>{
          newArrFile.push({name: filedNameArr[index], id: item, url: filedUrlArr[index]})
        })
        this.fileList = newArrFile
      }
      this.orderTaskList = resData.customerOrderStageList || [];
      this.feedbackList = resData.customerOrderFeedbackList || [];
      this.$refs["saleLead"].getNowStage(row, resData.customerOrderDetailVo);
      this.$refs["saleLead"].workLog(row.id);
      this.loading = false;

    },
    resetBtn() {
      // 重置
      this.$refs.commonReset.resetFun()
      this.params = {
        createFlag: this.activeName,
        page: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id,
      };
      if(this.activeName == '1'){
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
      }
      this.topDate = 1
      this.getRenewalList();
    },
    async getShopList(name) {
      // 所属店铺下拉
      let params = {...this.params}
      let resData = (await getShopList({shopName: name,companyId: params.companyId})).data;
      this.shopList = resData;
    },
    async getCustList(name) {
      // 所属客户下拉
      let params = {...this.params}
      let resData = (await getCustList({customerName: name,companyId: params.companyId})).data;
      this.custList = resData;
    },
    async commonDict(type) {
      // 字典值
      let resData = (await commonDict({}, type)).data;
      this.custDict[`${type}`] = resData;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.getRenewalList();
    },
    async getRenewalList() { // 客户订单列表
      let params = { ...this.params };
      // params.orderType = 1
      params.createFlag = this.activeName
      this.loading = true;
      let resData = (await getRenewalList(params)).data;
      this.loading = false;
      this.pagination.total = resData.listData.total;
      this.pagination.pageSize = resData.listData.size;
      this.tableData = resData.listData.list;
      this.dataAll = resData;
    },
    editPassword() {
      // 修改密码弹窗
      this.centerDialogVisible = true;
    },
    handleClose() {
      this.drawer = false;
      this.getRenewalList();
      this.getSelectOrderHeadNum();
      // 关闭新增员工弹窗
      // done();
    },
    handleCloseShop() {
      this.drawerShop = false;
      this.getRenewalList();
      // 关闭新增弹窗
      // done();
    },
    addShop(row) {
      //新增店铺
      this.drawerShop = true;
      if (row) {
        this.tableRow = row;
        this.$refs["cusListAdd"].getDataInfo("", row);
      }
    },
    getWeekDate () { // 获取本周日期
        let now = new Date(); //当前日期
        let nowDayOfWeek = now.getDay() || 7; //今天本周的第几天
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));
        return [weekStartDate, new Date()]
    },
    changeRadio(e) {
        let date;
        switch (e) {
            case 1:
            date = [(new Date()), (new Date())];
            break;
            case 2:
            date = getLatelyDay(0);
            break;
            case 3:
            date = this.getWeekDate()
            break;
            case 5:
            date = getSectionDate('month')
            break;
        }
        this.params2.startDate =  DateTransform(date[0])
        this.params2.endDate =  DateTransform(date[1])
        this.getSelectOrderHeadNum()
    },
    async getSelectOrderHeadNum(){
      let params = {...this.params2}
      params.companyId = this.params.companyId
      params.createFlag = this.activeName
      const { data } = await selectOrderHeadNum(params)
      this.dateRange = data
    },
    handleClick(tab) {
      if(tab.name == '1'){
        this.params.createId = this.$sto.get(Config.constants.userInfo).user_id
        this.getRenewalList()
      }else{
        delete this.params.createId
        this.getRenewalList()
      }
    },
  },
};
</script>
<style lang="less" scoped>

/deep/ .headGray {
  background: #f5f5f5 !important;
}
/deep/ .el-table th {
  background: transparent !important;
}
.tit-img {
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
}
.newLable {
  white-space: nowrap;
  .lable {
    font-size: 12px;
    line-height: 1;
    transform:scale(0.6);
  }
}
.tableBtn {
  color: #1890ff;
  cursor: pointer;
  display: inline-block;
}
.updateBtn {
  background: #1890ff;
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  width: 153px;
  height: 30px;
  line-height: 30px;
  margin: 20px auto 0;
}
.routeUpdate {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  font-size: 10px;
  line-height: 30px;
  vertical-align: middle;
  color: #666;
}
.detail-top {
  padding-left: 16px;
  .item-conteiner {
    display: inline-block;
    margin: 0 30px 24px 0;
    // width: 40%;
    .item-blue {
      color: #1890ff;
    }
  }
}
.topHeadDate {
  width: 120px;
  margin-left: 32px;
  /deep/.el-input__inner {
    padding-right: 0 !important;
  }
}
.SummaryItem-border {
  position: relative;
  &::after {
    position: absolute;
    content: ' ';
    display: block;
    width: 0;
    height: 50%;
    right: 0;
    top: 20%;
    bottom: 0;
    margin: auto;
    border-right: 1px solid #ccc;
  }
}
.items-conteiner {
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  padding: 0 0 12px 12px;
  margin-bottom: 12px;
  .itemOrder {
    font-size: 14px;
    padding: 5px 8px 5px 16px;
    background: #1890ff;
    border-radius: 4px;
    margin: 12px 12px 0 0;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
  }
  .deleteItem {
    padding: 0 4px;
    cursor: pointer;
    // font-size: 23px;
  }
}
.renewalOrder-container {
  text-align: left;
  .isMore-height {
    transition: height 0.5s;
    overflow: hidden;
    height: 292px;
  }
  .isMore-height0 {
    transition: height 0.5s;
    overflow: hidden;
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
  }
  /deep/.el-checkbox {
    margin-left: 6px;
  }
  .Summary-container {
    width: 100%;
    background-color: #fff;
    margin-bottom: 16px;
    position: relative;
    padding: 6px 0 25px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    &::after {
      display: block;
      content: " ";
      height: 1px;
      width: 120%;
      background-color: #e6e6e6;
      position: absolute;
      bottom: 0;
      left: -10%;
    }
    .item-border {
      border: 1px dashed #707070;
    }
    .Summary-item {
      width: 10%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 28px;

      .item-title {
        font-size: 15px;
        padding-top: 10px;
        color: #333;
      }
      .item-num {
        font-size: 28px;
        color: #1890ff;
        // font-weight: 700;
      }
    }
    .Summary-item-line {
      position: relative;
      &::after {
        content: " ";
        display: block;
        height: 49px;
        width: 1px;
        background-color: #b5b5b5;
        position: absolute;
        right: -15%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .Summary-item-line100 {
      position: relative;
      &::after {
        content: " ";
        display: block;
        height: 114px;
        width: 1px;
        background-color: #b5b5b5;
        position: absolute;
        right: -15%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .Summary-top {
    width: 100%;
    background-color: #fff;
    // margin-bottom: 16px;
    position: relative;
    padding: 6px 0 10px;
    display: flex;
    text-align: center;
    align-items: center;
    .Summary-item {
      margin-right: 65px;
      width: 9%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 28px;

      .item-title {
        font-size: 15px;
        padding-top: 10px;
        color: #333;
      }
      .item-num {
        font-size: 28px;
        color: #1890ff;
        // font-weight: 700;
      }
    }
  }
  .lableStatus-red {
    width: 80px;
    height: 28px;
    line-height: 26px;
    text-align: center;
    border: #f98787 1px solid;
    color: #f98787;
    border-radius: 4px;
  }
  .lableStatus-blue {
    width: 80px;
    height: 28px;
    line-height: 26px;
    text-align: center;
    border: #409eff 1px solid;
    color: #409eff;
    border-radius: 4px;
  }
  .lableStatus-green {
    width: 80px;
    height: 28px;
    line-height: 26px;
    text-align: center;
    border: #66CC1F 1px solid;
    color: #66CC1F;
    border-radius: 4px;
  }
  .lableStatus-gray {
    width: 80px;
    height: 28px;
    line-height: 26px;
    text-align: center;
    border: #707070 1px solid;
    color: #707070;
    border-radius: 4px;
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.order {
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  .order-tit {
    margin: 10px;
  }
}
.finger{
  cursor: pointer;
}
.gap{
  position: absolute;
  left: 0;
  right: 0;
  height: 16px;
  background-color: #ededed;
}
.common-table{
  margin: 64px auto 16px;
}

</style>
<style lang="less" scoped>
.common-screen-container {
    padding-left: 32px;
  }
/deep/ .breadcrumb-container{
    padding: 0 32px;
  }
  /deep/ .el-tabs__content{
    padding: 0 32px;
    overflow: visible !important;
  }
.iconfont{ 
  padding-right: 3px;
  font-size: 14px;
}
.label1{
  
  box-sizing: border-box;
  display: inline-block;
  color: #706EFF;
  border: 1px solid #706EFF;
  border-radius: 4px;
  background-color: #f4f4ff;
  font-size: 12px;
  padding: 4px 8px;
}
.label2{
  // height: 33px;
  display: inline-block;
  color: #CE358F;
  border: 1px solid #CE358F;
  border-radius: 4px;
  background-color: #fbeff6;
  font-size: 12px;
  padding: 4px 8px;
}
.label3{
  // height: 33px;
  display: inline-block;
  color: #F09009;
  border: 1px solid #F09009;
  border-radius: 4px;
  background-color: #fef6ec;
  font-size: 12px;
  padding: 4px 8px;
}
.el-tabs {
  margin-top: 32px;
}
.common-table{
  margin-top: 24px !important;
}
.title-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 20px 32px;
  align-items: center;
  position: relative;
  &::after {
    content: " ";
    background: #e6e6e6;
    width: 120%;
    height: 1px;
    position: absolute;
    left: -10%;
    bottom: -1px;
    z-index: 999999;
  }
  div {
    font-size: 24px;
    color: #101010;
  }
}
</style>
