<template>
  <div class="orderDetail-container">
    <el-drawer
      class="orderDetail-drawer"
      title="订单详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div
        v-loading="loading"
        element-loading-text="数据较多，拼命加载中..."
        class="drawer-container"
      >
        <div class="drawer-left">
          <div class="left-top">
            <el-form
              :model="editRuleForm"
              :rules="editRules"
              ref="editRuleForm"
              class="demo-ruleForm"
            >
              <div class="detail-title">
                <div class="tit-info">
                  {{ (tableRow && tableRow.orderNo) || "--" }}
                  <span
                    v-if="tableRow.status == 'process'"
                    class="common-tableStatus-red"
                    >续签预警</span
                  >
                  <span
                    v-if="tableRow.status == 'setFail'"
                    class="common-tableStatus-grey"
                    >续签失败</span
                  >
                  <span
                    v-if="tableRow.status == 'setDelete'"
                    class="common-tableStatus-grey"
                    >订单作废</span
                  >
                  <span
                    v-if="tableRow.status == 'fail'"
                    class="common-tableStatus-orange"
                    >续签超时</span
                  >
                  <span
                    v-if="tableRow.status == 'complete'"
                    class="common-tableStatus-blue"
                    >续签成功</span
                  >
                  <span
                    v-if="tableRow.status == 'servicing'"
                    class="common-tableStatus-green"
                    >正在服务</span
                  >
                  <span
                    v-if="tableRow.financialStatus == 'pending'"
                    class="orderState"
                    style="border: #F98787 1px solid;color: #F98787;"
                    >待销售催款</span
                  >
                  <span
                    v-if="tableRow.financialStatus == 'confirming'"
                    class="orderState"
                    style="border: #F09009 1px solid;color: #F09009;"
                    >待财务确认</span
                  >
                  <span
                    v-if="tableRow.financialStatus == 'complete'"
                    class="orderState"
                    style="border: #1890FF 1px solid;color: #1890FF;"
                    >交易完成</span
                  >
                  <span
                    v-if="this.payTypeName == '前置收款'"
                    style="display: inline-block;"
                  >
                    <span class="label1">
                      <i
                        class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "
                      ></i>
                      前置收款
                    </span>
                  </span>
                  <span
                    v-if="this.payTypeName == '前置服务'"
                    style="display: inline-block;"
                  >
                    <span class="label2">
                      <i
                        class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "
                      ></i
                      >前置服务
                    </span>
                  </span>
                  <span
                    v-if="this.payTypeName == '免费试用'"
                    style="display: inline-block;"
                  >
                    <span class="label3">
                      <i
                        class="iconfont icon-a-riFill-bookmark-3-fillCopy2 "
                      ></i
                      >免费试用
                    </span>
                  </span>
                </div>
              </div>
              <div class="detail-content">

                <el-form-item v-if="editParams.filedName == 'name' && isEdit" class="edit-input-container" prop="name" label="订单名称:">
                  <el-input class="edit-input" placeholder="请输入内容" ref="name" v-model="editRuleForm.name" @blur="() => {editCancelBtn('name')}">
                    <template slot="append">
                      <div @click="() => {editOrderDetail('name')}" class="cancel">确认</div>
                    </template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('name', OrderInfo.name)}" v-if="editParams.filedName != 'name' && isEdit" class="hoverEdit">
                  订单名称:
                  <span class="editColor">{{(OrderInfo && OrderInfo.name) || "--"}}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">订单名称:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.name) || "--"}}</span></div>
                
                <div class="DisableEdit">
                  订单类型:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.orderTypeName) || "--"
                  }}</span>
                </div>

                <div class="DisableEdit">
                  所属客户:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.customerName) || "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'paymentMethods' && isEdit"
                  class="edit-input-container"
                  prop="paymentMethods"
                  label="订单收款方式:"
                >
                  <el-select
                    class="edit-input"
                    v-model="editRuleForm.paymentMethods"
                    ref="paymentMethods"
                    placeholder="请选择"
                    @blur="
                      () => {
                        editCancelBtn('paymentMethods');
                      }
                    "
                    @change="
                      (e) => {
                        editOrderDetail('paymentMethods');
                      }
                    "
                    filterable
                  >
                    <el-option
                      v-for="item in payType"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div
                  v-if="editParams.filedName != 'paymentMethods' && isEdit"
                  @click="
                    () => {
                      editBtn('paymentMethods', OrderInfo.paymentMethods);
                    }
                  "
                  class="hoverEdit"
                >
                  订单收款方式:
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.paymentMethods) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">订单收款方式:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.paymentMethods) || "--"}}</span></div>

                <div class="DisableEdit"> 订单原价: <span class="common-clr-0a6">{{ OrderInfo && OrderInfo.originalPrice ? Number(OrderInfo.originalPrice || 0).toFixed(2) : "0.00" }}</span></div>

                <div class="DisableEdit" v-if="isEdit">
                  调价金额:
                  <span class="modifyPrice" @click="() => {editShow()}">{{ OrderInfo && OrderInfo.price ? (Number(OrderInfo.price) - Number(OrderInfo.originalPrice || 0)).toFixed(2) : "--" }}</span>
                </div>
                <div class="DisableEdit" v-if="!isEdit">
                  调价金额:
                  <span class="modifyPrice2">{{ OrderInfo && OrderInfo.price ? (Number(OrderInfo.price) - Number(OrderInfo.originalPrice || 0)).toFixed(2) : "--" }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'startTime' && isEdit"
                  class="edit-input-container"
                  prop="startTime"
                  label="开始时间:"
                >
                  <el-date-picker
                    :picker-options="pickerOptionsStart"
                    class="edit-input"
                    ref="startTime"
                    v-model="editRuleForm.startTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    @blur="
                      () => {
                        editCancelBtn('startTime');
                      }
                    "
                    @change="
                      (e) => {
                        editOrderDetail('startTime');
                      }
                    "
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('startTime', DateTransform(OrderInfo.startTime));
                    }
                  "
                  v-if="editParams.filedName != 'startTime' && isEdit && tableRow.financialStatus != 'complete'"
                  class="hoverEdit"
                >
                  开始时间:
                  <span class="editColor">{{
                    (OrderInfo && DateTransform(OrderInfo.startTime)) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit || tableRow.financialStatus == 'complete'" class="DisableEdit">开始时间: <span class="common-clr-0a6">{{(OrderInfo && DateTransform(OrderInfo.startTime)) || "--"}}</span></div>

                <el-form-item
                  v-if="editParams.filedName == 'endTime' && isEdit"
                  class="edit-input-container"
                  prop="endTime"
                  label="结束时间:"
                >
                  <el-date-picker
                    :picker-options="pickerOptionsEnd"
                    class="edit-input"
                    ref="endTime"
                    v-model="editRuleForm.endTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    @blur="
                      () => {
                        editCancelBtn('endTime');
                      }
                    "
                    @change="
                      (e) => {
                        editOrderDetail('endTime');
                      }
                    "
                    placeholder="选择日期"
                  ></el-date-picker>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('endTime', DateTransform(OrderInfo.endTime));
                    }
                  "
                  v-if="editParams.filedName != 'endTime' && isEdit && tableRow.financialStatus != 'complete'"
                  class="hoverEdit"
                >
                  结束时间:
                  <span class="editColor">{{
                    (OrderInfo && DateTransform(OrderInfo.endTime)) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit || tableRow.financialStatus == 'complete'" class="DisableEdit">结束时间:<span class="common-clr-0a6">{{(OrderInfo && DateTransform(OrderInfo.endTime)) || "--"}}</span></div>

                <div class="DisableEdit">
                  实收金额:
                  <span class="common-clr-0a6">{{
                    tableRow && tableRow.collectionAmount
                      ? Number(tableRow.collectionAmount).toFixed(2)
                      : 0
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'agreeShopNum' && isEdit"
                  class="edit-input-container"
                  prop="agreeShopNum"
                  label="协议店铺数:"
                >
                  <el-input
                    class="edit-input"
                    ref="agreeShopNum"
                    placeholder="请输入内容"
                    v-model="editRuleForm.agreeShopNum"
                    type="number"
                    @mousewheel.native.prevent
                    @blur="
                      () => {
                        editCancelBtn('agreeShopNum');
                      }
                    "
                  >
                    <template slot="append">
                      <div
                        @click="
                          () => {
                            editOrderDetail('agreeShopNum');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('agreeShopNum', OrderInfo.agreeShopNum);
                    }
                  "
                  v-if="editParams.filedName != 'agreeShopNum' && isEdit"
                  class="hoverEdit"
                >
                  协议店铺数:
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.agreeShopNum) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">协议店铺数:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.agreeShopNum) || "--"}}</span></div>
                <!-- <el-form-item v-if="editParams.filedName == 'agreeSubNum'" class="edit-input-container" prop="agreeSubNum" label="协议子账号数:">
                  <el-input class="edit-input" ref="agreeSubNum" placeholder="请输入内容" v-model="editRuleForm.agreeSubNum" type="number"  @mousewheel.native.prevent @blur="() => {editCancelBtn('agreeSubNum')}">
                    <template slot="append">
                      <div @click="() => {editOrderDetail('agreeSubNum')}" class="cancel">确认</div>
                    </template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('agreeSubNum', OrderInfo.agreeSubNum)}" v-if="editParams.filedName != 'agreeSubNum'" class="hoverEdit">
                  协议子账号数:
                  <span class="editColor">{{(OrderInfo && OrderInfo.agreeSubNum) || "--"}}</span>
                </div> -->
                <div class="DisableEdit">
                  协议子账号数:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.agreeSubNum) || "--"
                  }}</span>
                </div>

                <div class="DisableEdit">
                  未收金额:
                  <span class="common-clr-0a6">{{
                    tableRow && tableRow.uncollectionAmount
                      ? Number(tableRow.uncollectionAmount).toFixed(2)
                      : 0
                  }}</span>
                </div>

                <!-- <el-form-item v-if="editParams.filedName == 'conslutDayNum'" class="edit-input-container editInputContainer" prop="conslutDayNum" label="协议单账号日均咨询量:">
                  <el-input class="edit-input" ref="conslutDayNum" placeholder="请输入内容" v-model="editRuleForm.conslutDayNum" type="number"  @mousewheel.native.prevent @blur="() => {editCancelBtn('conslutDayNum')}">
                    <template slot="append">
                      <div @click="() => {editOrderDetail('conslutDayNum')}" class="cancel">确认</div>
                    </template>
                  </el-input>
                </el-form-item>
                <div @click="() => {editBtn('conslutDayNum', OrderInfo.conslutDayNum)}" v-if="editParams.filedName != 'conslutDayNum'" class="hoverEdit">
                  协议单账号日均咨询量:
                  <span class="editColor">{{(OrderInfo && OrderInfo.conslutDayNum) || "--"}}</span>
                </div> -->
                <div class="DisableEdit">
                  协议单账号日均咨询量:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.conslutDayNum) || "--"
                  }}</span>
                </div>

                <el-form-item
                  v-if="editParams.filedName == 'type' && isEdit"
                  class="edit-input-container"
                  prop="type"
                  label="业务类型:"
                >
                  <el-select
                    class="edit-input"
                    v-model="editRuleForm.type"
                    ref="type"
                    placeholder="请选择"
                    @blur="
                      () => {
                        editCancelBtn('type');
                      }
                    "
                    @change="
                      (e) => {
                        editOrderDetail('type');
                      }
                    "
                    filterable
                  >
                    <el-option
                      v-for="item in custDict['12']"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div
                  v-if="editParams.filedName != 'type' && isEdit"
                  @click="
                    () => {
                      editBtn('type', OrderInfo.type);
                    }
                  "
                  class="hoverEdit"
                >
                  业务类型:
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.typeName) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">业务类型:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.typeName) || "--"}}</span></div>

                <el-form-item
                  v-if="editParams.filedName == 'introducerName' && isEdit"
                  class="edit-input-container editInputContainer"
                  prop="introducerName"
                  label="介绍人名称:"
                >
                  <el-input
                    class="edit-input"
                    ref="introducerName"
                    placeholder="请输入内容"
                    v-model="editRuleForm.introducerName"
                    @blur="
                      () => {
                        editCancelBtn('introducerName');
                      }
                    "
                  >
                    <template slot="append">
                      <div
                        @click="
                          () => {
                            editOrderDetail('introducerName');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('introducerName', OrderInfo.introducerName);
                    }
                  "
                  v-if="editParams.filedName != 'introducerName' && isEdit"
                  class="hoverEdit"
                >
                  介绍人名称:
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.introducerName) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">介绍人名称:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.introducerName) || "--"}}</span></div>

                <el-form-item
                  v-if="editParams.filedName == 'introducerPhone' && isEdit"
                  class="edit-input-container editInputContainer"
                  prop="introducerPhone"
                  label="介绍人电话:"
                >
                  <el-input
                    class="edit-input"
                    ref="introducerPhone"
                    placeholder="请输入内容"
                    v-model="editRuleForm.introducerPhone"
                    type="number"
                    @mousewheel.native.prevent
                    @blur="
                      () => {
                        editCancelBtn('introducerPhone');
                      }
                    "
                  >
                    <template slot="append">
                      <div
                        @click="
                          () => {
                            editOrderDetail('introducerPhone');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('introducerPhone', OrderInfo.introducerPhone);
                    }
                  "
                  v-if="editParams.filedName != 'introducerPhone' && isEdit"
                  class="hoverEdit"
                >
                  介绍人电话:
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.introducerPhone) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">介绍人电话:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.introducerPhone) || "--"}}</span></div>

                <el-form-item
                  v-if="editParams.filedName == 'introducerPrice' && isEdit"
                  class="edit-input-container editInputContainer"
                  prop="introducerPrice"
                  label="介绍费:"
                >
                  <el-input
                    class="edit-input"
                    ref="introducerPrice"
                    placeholder="请输入内容"
                    v-model="editRuleForm.introducerPrice"
                    type="number"
                    @mousewheel.native.prevent
                    @blur="
                      () => {
                        editCancelBtn('introducerPrice');
                      }
                    "
                  >
                    <template slot="append">
                      <div
                        @click="
                          () => {
                            editOrderDetail('introducerPrice');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('introducerPrice', OrderInfo.introducerPrice);
                    }
                  "
                  v-if="editParams.filedName != 'introducerPrice' && isEdit"
                  class="hoverEdit"
                >
                  介绍费:
                  <span class="editColor">{{
                    OrderInfo && OrderInfo.introducerPrice
                      ? Number(OrderInfo.introducerPrice).toFixed(2)
                      : "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">介绍费:<span class="common-clr-0a6">{{ OrderInfo && OrderInfo.introducerPrice ? Number(OrderInfo.introducerPrice).toFixed(2) : "--"}}</span></div>

                <el-form-item
                  v-if="editParams.filedName == 'note' && isEdit"
                  class="note-input-container"
                  prop="note"
                  label="订单备注:"
                >
                  <el-input
                    class="edit-input"
                    ref="note"
                    placeholder="请输入内容"
                    v-model="editRuleForm.note"
                    @blur="
                      () => {
                        editCancelBtn('note');
                      }
                    "
                  >
                    <template slot="append">
                      <div
                        @click="
                          () => {
                            editOrderDetail('note');
                          }
                        "
                        class="cancel"
                      >
                        确认
                      </div>
                    </template>
                  </el-input>
                </el-form-item>
                <div
                  @click="
                    () => {
                      editBtn('note', OrderInfo.note);
                    }
                  "
                  v-if="editParams.filedName != 'note' && isEdit"
                  class="noteHoverEdit"
                >
                  <span class="noteName">订单备注:</span>
                  <span class="editColor">{{
                    (OrderInfo && OrderInfo.note) || "--"
                  }}</span>
                </div>
                <div v-if="!isEdit" class="DisableEdit">订单备注:<span class="common-clr-0a6">{{(OrderInfo && OrderInfo.note) || "--"}}</span></div>
              </div>
            </el-form>
            <div class="rightBtn-group">
              <el-button v-if="isEdit" class="common-screen-btn" type="primary" @click="() => { incomeShow() }">发起收款</el-button>
              <div v-if="isEdit" class="Btn-container" @click="() => againOrder()">再来一单</div>
              <div v-if="isEdit" class="Btn-container" @click="() => addShop(OrderInfo)">新增店铺</div>
              <div v-if="tableRow.status == 'fail' && isEdit" class="Btn-container" @click="() => { setFailVisible = true }">续签失败</div>
              <div v-if="tableRow.status != 'setDelete' && isEdit" class="Btn-container" @click="() => { setDeleteShow() }">作废</div>
            </div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                订单合同
              </div>
              <div class="tip" v-if="isEdit">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">
                  上传附件
                  <input @input="(e) => { uploadFile(e) }" class="uploadBtn-input" type="file"/>
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="fileItem"
              >
                <div class="fileItem-name">
                  <common-picture
                    :fileUrl="item.url"
                    :fileType="
                      Config.fileType.imgType.includes(
                        item.url.split('.')[item.url.split('.').length - 1]
                      )
                        ? 'imgText'
                        : 'file'
                    "
                    :fileName="item.name"
                  ></common-picture>
                  <!-- {{item.name}} -->
                </div>
                <div class="fileItem-btn-Container">
                  <!-- <div class="fileItemBtn" @click="() => {download(item.url)}">查看</div> -->
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.url);
                      }
                    "
                  >
                    下载
                  </div>
                  <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                  <!-- <div class="fileItemBtn" @click="() => {deleteFile(item)}">删除</div> -->
                </div>
              </div>
            </div>
            <div
              v-if="!fileList || !fileList.length"
              class="fileList-container"
            >
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>
          <el-tabs
            class="tabsDetail"
            v-model="activeTab"
            @tab-click="handleClick"
          >
            <el-tab-pane label="阶段任务" name="1">
              <div>
                <div class="ServiceFollow-content">
                  <div v-for="(item, index) in orderTaskList" :key="index">
                    <div
                      class="ServiceFollow-item"
                      v-if="OrderInfo.orderStep == item.step"
                    >
                      <!-- <div class="ServiceFollow-item" v-if="OrderInfo.orderStep == item.step && item.groupTaskId > 0"> -->
                      <el-checkbox
                        :disabled="!isEdit"
                        :value="item.orderStageState == 0 ? false : true"
                        @change="(value) => orderStateFun(value, item.id)"
                        >{{ item.name }}</el-checkbox
                      >
                      <div v-if="item.orderStageState == '1'">
                        {{ `${item.createName}于${item.completeTime}完成` }}
                      </div>
                    </div>
                  </div>
                  <div class="feedback">
                    <div class="steps-container">
                      <div class="steps-left">服务阶段</div>
                      <div class="steps-center">
                        <el-steps
                          :active="
                            OrderInfo.orderStep == '16'
                              ? 1
                              : OrderInfo.orderStep == '17'
                              ? 2
                              : OrderInfo.orderStep == '18'
                              ? 3
                              : 3
                          "
                        >
                          <el-step
                            title="初期信息跟进"
                            icon="el-icon-s-help"
                          ></el-step>
                          <el-step
                            title="中期服务开展"
                            icon="el-icon-s-help"
                          ></el-step>
                          <el-step
                            title="后期续费洽谈"
                            icon="el-icon-s-help"
                          ></el-step>
                        </el-steps>
                      </div>
                    </div>
                    <span @click="showFeedback = !showFeedback"
                      >共{{ feedbackList.length || "0" }}条反馈</span
                    >
                    <el-button
                      v-if="nextStage && OrderInfo.orderStep != 18"
                      class="common-screen-btn"
                      type="primary"
                      @click="() => orderNext()"
                      >进入下一阶段</el-button
                    >
                  </div>
                  <div
                    v-if="showFeedback || (feedbackList && feedbackList.length)"
                    class="feedback-content"
                  >
                    <el-input
                      v-if="showFeedback"
                      type="textarea"
                      :autosize="{ minRows: 6, maxRows: 6 }"
                      placeholder="请输入内容"
                      v-model="feedbackContent"
                    ></el-input>
                    <div>
                      <div class="upload-userIcon" v-if="showFeedback">
                        <el-button
                          class="common-screen-btn"
                          type="primary"
                          style="margin-left: 0"
                          >上传图片</el-button
                        >
                        <input
                          class="common-upload-input"
                          type="file"
                          @input="(e) => chooseFile(e, 'img')"
                        />
                        <div class="fileNameText" v-if="pictureNameText">
                          {{ pictureNameText }}
                        </div>
                      </div>
                      <div class="upload-userIcon" v-if="showFeedback">
                        <el-button
                          class="common-screen-btn"
                          type="primary"
                          style="margin-left: 0"
                          >上传文件</el-button
                        >
                        <input
                          class="common-upload-input"
                          type="file"
                          @input="(e) => chooseFile(e, 'xlsx')"
                        />
                        <div class="fileNameText" v-if="fileNameText">
                          {{ fileNameText }}
                        </div>
                      </div>
                      <div class="upload-commit" v-if="showFeedback">
                        <div class="com-btns">
                          <el-button
                            class="common-screen-btn"
                            type="primary"
                            @click="() => addFeedback()"
                            >提交</el-button
                          >
                          <el-button class="common-screen-btn">取消</el-button>
                        </div>
                      </div>
                      <div v-if="feedbackList" class="SubmitPlan-container">
                        <div
                          class="feedback-item"
                          v-for="(item, index) in feedbackList"
                          :key="index + 'feedback'"
                        >
                          <div class="item-row">{{ item.planTime || "" }}</div>
                          <div class="item-row">
                            <el-button
                              class="item-user"
                              type="text"
                              size="small"
                              >{{ item.createName || "" }}</el-button
                            >新增了反馈问题
                          </div>
                          <div class="item-row" v-if="item.content">
                            问题：{{ item.content }}
                          </div>
                          <div style="margin-top: 16px" class="item-row">
                            <common-picture
                              style="margin-right: 16px"
                              v-if="item.picture"
                              :fileUrl="item.picture"
                              :fileType="'imgText'"
                              :fileName="'反馈图片'"
                            ></common-picture>
                            <common-picture
                              v-if="item.cmsFile"
                              :fileUrl="item.cmsFile"
                              :fileType="'file'"
                              :fileName="'反馈文件'"
                            ></common-picture>
                          </div>
                          <!-- <div class="item-row">
                            
                            <img class="feedbackImg" @click="() => downLoad(item.picture)"  v-if="item.picture" :src="item.picture" alt="反馈" />
                            <img class="feedbackImg" @click="() => downLoad(item.cmsFile)" v-if="item.cmsFile" src="../../../assets/images/file_icon.png" alt=""/>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="进账信息" name="2">
              <el-table class="common-table" :data="flowTableData" :fit="true">
                <!-- <el-table-column prop="code" label="流水编号" :formatter="tableColumn"></el-table-column> -->
                <el-table-column label="流水编号" width="120px">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      v-if="
                        scope.row.status == 'unconfirmed' &&
                          scope.row.deleteFlag == '0'
                      "
                      @click="() => flowDetail(scope.row.id, 'edit')"
                      >{{ scope.row.code }}</el-button
                    >
                    <!-- <el-button type="text" size="small" v-else-if="scope.row.deleteFlag == '1'" style="color: #ccc;text-decoration: line-through">{{scope.row.code}}</el-button> -->
                    <el-button
                      type="text"
                      size="small"
                      v-else
                      @click="() => flowDetail(scope.row.id, 'detail')"
                      >{{ scope.row.code }}</el-button
                    >
                    <!-- <div>{{scope.row.code}}</div> -->
                  </template>
                </el-table-column>
                <el-table-column label="金额">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">
                      <span style="color:#F09009">+{{ scope.row.amount }}</span>
                      <br />
                      <span
                        v-if="scope.row.financialStatus == 'historyFlow'"
                        class="common-tableStatus-red"
                        >历史收款</span
                      >
                      <span
                        v-if="scope.row.financialStatus == 'receivable'"
                        class="common-tableStatus-orange"
                        >当月应收</span
                      >
                      <span
                        v-if="scope.row.financialStatus == 'advanceCharge'"
                        class="common-tableStatus-green"
                        >预付款</span
                      >
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="收支方式">
                  <template slot-scope="scope">
                    <div v-if="scope.row.recordType == 'bankCard'">银行卡</div>
                    <div v-if="scope.row.recordType == 'alipay'">支付宝</div>
                    <div v-if="scope.row.recordType == 'wechat'">微信</div>
                    <div v-if="scope.row.recordType == 'cash'">现金</div>
                    <div v-if="scope.row.recordType == 'corporateAccount'">
                      对公账户
                    </div>
                    <div v-if="scope.row.recordType == 'generalAccount'">
                      对公账户（总）
                    </div>
                    <div v-if="scope.row.recordType == 'publicAccount'">
                      对公账户（分）
                    </div>
                    <div v-if="scope.row.recordType == 'taobao'">淘宝</div>
                    <div v-if="scope.row.recordType == 'tiktok'">抖音</div>
                    <div v-if="scope.row.recordType == 'pinduoduo'">拼多多</div>
                    <div v-if="scope.row.recordType == 'other'">其他</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="confirmRecordTime"
                  label="确认到账时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="confirmedName"
                  label="审批人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="审批状态">
                  <template slot-scope="scope">
                    <div
                      v-if="scope.row.deleteFlag == '1'"
                      class="common-tableStatus-grey"
                    >
                      已作废
                    </div>
                    <div
                      v-else-if="scope.row.status == 'unconfirmed'"
                      class="common-tableStatus-red"
                    >
                      审核中
                    </div>
                    <div v-else class="common-tableStatus-blue">已通过</div>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      v-if="
                        scope.row.status == 'unconfirmed' &&
                          scope.row.deleteFlag == '0'
                      "
                      @click="() => voidShow(scope.row.id)"
                      >作废</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      v-else
                      style="color: #ccc"
                      >作废</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunFlow(e, 'pageSize')"
                @current-change="(e) => pageFunFlow(e)"
                style="text-align: center"
                :page-sizes="paginationFlow.pageSizes"
                :page-size="flowParams.pageSize"
                :current-page="flowParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationFlow.total"
              ></el-pagination>
              <br />
            </el-tab-pane>
            <el-tab-pane :label="'店铺列表(' + pagination.total + ')'" name="3">
              <div style="text-align: right">
                <div class="common-blue-button" @click="() => { changeFun() }">一键均分</div>
              </div>
              <el-table class="common-table" :data="shopTableData">
                <el-table-column prop="shopName" label="店铺名称">
                  <template slot-scope="scope">
                    <el-button style="white-space: normal;text-align: left" type="text" size="small" @click="() => {openNewWin(`/customer/company-shop-list?shopId=${scope.row.id}&shopName=${scope.row.shopName}&from=order`)}">{{ scope.row.shopName || "--" }}</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="服务时间">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{scope.row.startTime && scope.row.endTime ? `${DateTransform(scope.row.startTime)}\n${DateTransform(scope.row.endTime)}` : '--'}}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="platformName"
                  label="所属平台"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="categoryName"
                  label="所属类目"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="店铺金额">
                  <template slot-scope="scope">
                    <el-popover @show="() => { shopPopoShow(scope.row.id, scope.row.price || 0, scope.$index) }" :value="scope.row.popoVisi" placement="top" width="300" trigger="click">
                      <el-form ref="shopPriceOne" :model="shopPriceOne">
                        <span class="shopPopoTitle">修改店铺金额</span>
                        <el-form-item prop="price" :rules="[{ validator: (rule, value, callback) => { priceValid(value, callback, scope.row.price) } , trigger: 'change' }]">
                          <el-input class="price-input" type="text" v-model="shopPriceOne.price">
                            <div class="input-after-btn" slot="suffix" @click="() => { changeOneShop() }">确认</div>
                          </el-input>
                        </el-form-item>
                      </el-form>
                      <el-button v-if="scope.row.price" type="text" slot="reference">{{scope.row.price}}</el-button>
                      <el-button v-else type="text" slot="reference">0.00</el-button>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="busineseUser"
                  label="运营负责人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createName"
                  label="创建人"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :formatter="tableColumn"
                ></el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      style="white-space: normal;text-align: left"
                      type="text"
                      size="small"
                      @click="
                        () => {
                          openNewWin(
                            `/customer/company-shop-list/subaccount-list?isAssign=0&id=${scope.row.id}&shopName=${scope.row.shopName}&companyId=${scope.row.companyId}&departName=${scope.row.departName}&categoryName=${scope.row.categoryName}&platformName=${scope.row.platformName}&customerName=${scope.row.customerName}&startTime=${scope.row.startTime}&endTime=${scope.row.endTime}&shopLogo=${scope.row.shopLogo}&fileUrl=${scope.row.fileUrl}&from=order`
                          );
                        }
                      "
                      >子账号管理</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFun(e, 'pageSize')"
                @current-change="(e) => pageFun(e)"
                style="text-align: center"
                :page-sizes="pagination.pageSizes"
                :page-size="shopParams.pageSize"
                :current-page="shopParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="pagination.total"
              ></el-pagination>
              <br />
            </el-tab-pane>
            <el-tab-pane label="订单调价" name="4">
              <el-table class="common-table" :data="changeTableData">
                <el-table-column prop="orderNo" label="订单调价编号" width="140px" fixed>
                  <template slot-scope="scope">
                    <el-button style="white-space: normal;text-align: left" @click="() => { openNewWin(`/work-order/approve?workOrderName=${scope.row.workOrderName}&workOrderId=${scope.row.workOrderId}`) }" type="text" size="small">{{ scope.row.orderNo || "--" }}</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="订单应收金额" prop="orderPrice" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价类型" width="120px"> 
                  <template slot-scope="scope">
                    <div v-if="scope.row.type == 0">加班费</div>
                    <div v-else-if="scope.row.type == 1">咨询量差价</div>
                    <div v-else-if="scope.row.type == 2">增减坐席/人力</div>
                    <div v-else-if="scope.row.type == 3">服务扣款</div>
                    <div v-else-if="scope.row.type == 5">服务对接群</div>
                    <div v-else-if="scope.row.type == 6">增减服务时长</div>
                    <div v-else-if="scope.row.type == 4">其他</div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column label="店铺" prop="shopNames" :formatter="tableColumn" width="140px">
                  <template slot-scope="scope">
                    <el-tooltip v-if="scope.row.shopNames" class="item" effect="dark" :content="scope.row.shopNames" placement="bottom">
                      <div style="white-space: pre-line">{{ commonLineFeed(scope.row.shopNames) }}</div>
                    </el-tooltip>
                    <div v-else style="white-space: pre-line">{{ commonLineFeed(scope.row.shopNames) }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="时间段" width="120px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{scope.row.startTime && scope.row.endTime ? `${DateTransform(scope.row.startTime)}\n${DateTransform(scope.row.endTime)}` : '--'}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="调价金额" prop="changeMoneny" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价结果" prop="result" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="改价说明" prop="remark" show-overflow-tooltip :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="审批状态" width="120px">
                  <template slot-scope="scope">
                    <div style="color: #52C419" v-if="scope.row.workStatus == 1">处理中</div>
                    <div style="color: #1890ff" v-else-if="scope.row.workStatus == 2">已完成</div>
                    <div style="color: #FF4D4F" v-else-if="scope.row.workStatus == 3">拒绝</div>
                    <div style="color: #aaa" v-else-if="scope.row.workStatus == 4">撤回</div>
                    <div v-else>--</div>
                  </template>
                </el-table-column>
                <el-table-column label="创建人" prop="createName" :formatter="tableColumn" width="140px"></el-table-column>
                <el-table-column label="创建时间" width="140px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{formatDateTimer(scope.row.createTime)}}</div>
                  </template>
                </el-table-column>
                <el-table-column label="最后处理时间" width="140px">
                  <template slot-scope="scope">
                    <div style="white-space: pre-line">{{formatDateTimer(scope.row.updateTime)}}</div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="(e) => pageFunChange(e, 'pageSize')"
                @current-change="(e) => pageFunChange(e)"
                style="text-align: center"
                :page-sizes="paginationChange.pageSizes"
                :page-size="changeParams.pageSize"
                :current-page="shopParams.pageNum"
                layout="total, prev, pager, next,sizes, jumper"
                :total="paginationChange.total"
              ></el-pagination>
              <br />
            </el-tab-pane>
            <el-tab-pane label="基本信息" name="5">
              <div class="detail-content">
                <div class="DisableEdit">
                  订单负责人:
                  <span class="common-clr-0a6">{{
                    (adminInfo && adminInfo.nickName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  订单负责人所属部门:
                  <span class="common-clr-0a6">{{
                    (adminInfo && adminInfo.userDepartName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  创建人:
                  <span class="common-clr-0a6">{{
                    (adminInfo && adminInfo.nickName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  创建时间:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.createTime) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  最后修改人:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.createName) || "--"
                  }}</span>
                </div>
                <div class="DisableEdit">
                  最后修改时间:
                  <span class="common-clr-0a6">{{
                    (OrderInfo && OrderInfo.updateTime) || "--"
                  }}</span>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="drawer-right">
          <el-tabs
            style="height: 100%;display: flex;flex-direction: column;"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane label="工作记录" name="about">
              <div class="about-content">
                <textarea
                  class="common-textarea-log"
                  @paste="handlePaste"
                  v-model="recordContent"
                  placeholder="工作小计"
                  rows="6"
                ></textarea>
                <div
                  class="fileNames"
                  v-for="(item, index) in filesList"
                  :key="index"
                >
                  <div>
                    <common-picture
                      :fileUrl="item.fileUrl"
                      :fileType="item.fileType"
                      :fileName="item.fileName"
                    ></common-picture>
                  </div>
                  <div
                    class="common-blue-btn"
                    @click="() => deleteFileFlow(item.fileId)"
                  >
                    删除
                  </div>
                </div>
                <div class="con-rec">
                  <div>
                    <div class="addFileBtn">
                      <img
                        style="margin-right: 8px"
                        src="../../../assets/images/add_file_icon.svg"
                      />添加附件
                      <input
                        class="addFileInput"
                        :multiple="false"
                        @input="chooseFileFlow"
                        type="file"
                      />
                    </div>
                    <div style="font-size: 12px; color: #ccc">
                      上传文件及图片，大小不超过50M
                    </div>
                  </div>
                  <el-button
                    :loading="btnLoading"
                    class="common-screen-btn"
                    type="primary"
                    @click="() => addOrderFollow()"
                    >发布</el-button
                  >
                </div>
              </div>
              <common-work-log
                :followRecordList="followRecordList"
              ></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      v-dialogDrag
      title="再来一单"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="520px"
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          :model="ruleFormAdd"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="客户名称">
            {{ ruleFormAdd.custName }}
          </el-form-item>
          <el-form-item label="订单类型" prop="orderType">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.orderType"
              placeholder="请选择"
              filterable
              @change="orderChange"
            >
              <el-option
                v-for="item in orderTypeOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              placeholder="订单名称"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="运营指派" prop="toId">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.toId"
              placeholder="请选择指派人"
              filterable
            >
              <el-option
                v-for="item in comPeopleList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单收款方式" prop="paymentMethods">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.paymentMethods"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in payType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="通知人" prop="noticeId">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.noticeId"
              placeholder="请选择通知人"
              filterable
            >
              <el-option
                v-for="item in userList"
                :key="item.id"
                :label="item.nickName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单金额" prop="price">
            <el-input
              class="common-input-YUAN"
              type="number"
              @mousewheel.native.prevent
              v-model="ruleFormAdd.price"
              placeholder="销售机会金额"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单开始时间" prop="startTime">
            <el-date-picker
              v-show="ruleFormAdd.orderType !='2'"
              class="dialog-input"
              v-model="ruleFormAdd.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择开始时间"
              :picker-options="pickerOptionsStartNew"
              @change="() => { changeDateFun('start') }"
            ></el-date-picker>
            <el-date-picker
              v-show="ruleFormAdd.orderType =='2'"
              class="dialog-input"
              v-model="ruleFormAdd.startTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择开始时间"
              :picker-options="pickerOptionsOrderStart"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="订单结束时间" prop="endTime">
            <el-date-picker
              v-show="ruleFormAdd.orderType !='2'"
              class="dialog-input"
              v-model="ruleFormAdd.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择结束时间"
              @change="() => { changeDateFun('end') }"
              :picker-options="pickerOptionsEndNew"
            ></el-date-picker>
            <el-date-picker
              v-show="ruleFormAdd.orderType =='2'"
              class="dialog-input"
              v-model="ruleFormAdd.endTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择结束时间"
              
              :picker-options="pickerOptionsOrderEnd"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="协议店铺数" prop="agreeShopNum">
            <el-input
              type="number"
              @mousewheel.native.prevent
              class="dialog-input"
              v-model="ruleFormAdd.agreeShopNum"
              placeholder="协议店铺数"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="ruleFormAdd.note"
              maxLength="500"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="ruleFormAdd.orderType =='2'">
            <el-checkbox v-model="shopChecked"
              >是否关联此订单当前店铺</el-checkbox
            >
          </el-form-item>
          <div class="introducer" v-if="shopChecked && ruleFormAdd.orderType =='2'">
            <el-form-item label="关联店铺">
              <el-select
                class="dialog-input"
                v-model="shopSelectOld"
                placeholder="请选择关联店铺"
                @change="(val) => shopOldChange(val)"
                clearable
                filterable
              >
                <el-option
                  v-for="item in shopOldList"
                  :key="item.id"
                  :label="item.shop_name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div class="orderShop-old">
                <div
                  v-for="(item, index) in shopCheckedList"
                  :key="index"
                  class="oldItem"
                >
                  {{ item.shop_name
                  }}<span @click="() => deleteShop(index)" class="close"
                    >×</span
                  >
                </div>
              </div>
            </el-form-item>
          </div>
          <el-form-item>
            <el-checkbox v-model="checked">是否有介绍人</el-checkbox>
          </el-form-item>
          <div class="introducer" v-if="checked">
            <el-form-item label="介绍人名称">
              <el-input
                class="dialog-input"
                v-model="ruleFormAdd.introducerName"
                placeholder="介绍人名称"
                maxLength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍人联系方式">
              <el-input
                class="dialog-input"
                v-model="ruleFormAdd.introducerPhone"
                placeholder="介绍人联系方式"
                maxLength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="介绍费" prop="introducerPrice">
              <el-input
                class="common-input-YUAN"
                type="number"
                @mousewheel.native.prevent
                v-model="ruleFormAdd.introducerPrice"
                placeholder="介绍费"
                maxLength="50"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          :loading="btnLoading"
          type="primary"
          @click="() => subNewOrderForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="订单调价"
      :append-to-body="true"
      :visible.sync="editVisible"
      width="60%"
      :close-on-click-modal="false"
    >
     <template slot="title">
        <el-tooltip class="item" effect="dark" content="【用户说明】订单暂无已收款可直接发起调价" placement="bottom" enterable >
            <span>订单调价<img src="../../../assets/images/question.png" class="tit-img"/></span>
        </el-tooltip> 
      </template>
      <div v-loading="loading" element-loading-text="数据较多，拼命加载中...">
        <el-form
          :model="ruleFormEdit"
          :rules="ruleEditRules"
          ref="ruleFormEdit"
          label-width="110px"
          class="demo-ruleForm"
        >
          <div class="form-left">
            <el-form-item label="订单应收金额：">
              <el-input disabled class="common-input-YUAN" v-model="ruleFormEdit.originalMoneny"></el-input>
            </el-form-item>
            <el-form-item label="改价类型：">
              <el-radio-group v-model="ruleFormEdit.type">
                <el-radio :label="0">加班费</el-radio>
                <el-radio :label="1">咨询量差价</el-radio>
                <el-radio :label="2">增减坐席/人力</el-radio>
                <el-radio :label="3">服务扣款</el-radio>
                <el-radio :label="5">服务对接群</el-radio>
                <el-radio :label="6">增减服务时长</el-radio>
                <el-radio :label="4">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="overtime-container">
              <el-form-item label="店铺" prop="shopIds">
                <el-select
                  v-if="shopSelect"
                  class="common-screen-input_100"
                  v-model="ruleFormEdit.shopIds"
                  placeholder="请选择店铺"
                  multiple
                  filterable
                >
                  <el-option
                    v-for="item in shopSelect"
                    :key="item.id + ''"
                    :label="item.shopName + ''"
                    :value="item.id + ''"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="时间段" prop="datePicker">
                <el-date-picker
                  class="common-screen-input_100"
                  v-model="ruleFormEdit.datePicker"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptionsPrice"
                ></el-date-picker>
              </el-form-item>
              <el-form-item v-if="ruleFormEdit.type == 0" label="子账号数量">
                <el-input
                  class="common-screen-input_100"
                  v-model="ruleFormEdit.agreeSubNum"
                  type="number"
                  @mousewheel.native.prevent
                  maxLength="50"
                ></el-input>
              </el-form-item>
            </div>
            <el-form-item label="调整金额：" prop="changeMoneny">
              <el-input
                class="common-input-YUAN"
                v-model="ruleFormEdit.changeMoneny"
                type="number"
                @mousewheel.native.prevent
                maxLength="50"
                @input="
                  (e) => {
                    changePrice(e);
                  }
                "
              ></el-input>
            </el-form-item>
            <el-form-item label="改价结果：">
              <el-input disabled class="common-input-YUAN" v-model="ruleFormEdit.result" ></el-input>
            </el-form-item>
          </div>
          <div class="form-right">
            <el-form-item label="通知人：" prop="noticeId">
              <el-select
                v-if="noticeList && noticeList.length"
                class="common-screen-input_100"
                v-model="ruleFormEdit.noticeId"
                placeholder="请选择通知人"
                multiple
                filterable
              >
                <el-option
                  v-for="item in noticeList"
                  :key="item.id"
                  :label="item.nickName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="改价说明：">
              <el-input
                class="common-screen-input_100"
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 6 }"
                placeholder="请输入内容"
                v-model="ruleFormEdit.remark"
              ></el-input>
            </el-form-item>
          </div>
          <div class="grey-line"></div>
          <br />
          <el-form-item label="改价记录：">
            <div
              class="record-container"
              v-if="priceRecord && priceRecord.length"
            >
              <div
                class="record-item"
                v-for="(item, index) in priceRecord"
                :key="index"
              >
                <div class="recordItem-title">
                  <div class="title-left">
                    <span></span>
                    <span>{{ item.operationName || "--" }}</span>
                  </div>
                  <div style="color:#999">
                    {{ item.time }}
                  </div>
                </div>
                <div class="recordItem-content">
                  <div class="content-item">【订单调价】</div>
                  <div
                    v-for="(itemTwo, indexTwo) in item.value"
                    :key="indexTwo"
                  >
                    <div v-if="indexTwo != 0">
                      <div
                        class="content-item"
                        v-for="(key, i) in itemTwo"
                        :key="i"
                      >
                        {{ i }}：{{ key }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="record-container" v-else>
              暂无记录
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="editVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          :loading="btnLoading"
          type="primary"
          @click="
            () => {
              orderPriceEdit('ruleFormEdit');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="作废说明"
      :append-to-body="true"
      :visible.sync="voidVisible"
      width="480px"
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          :model="voidForm"
          :rules="voidRules"
          ref="voidForm"
          label-width="80px"
          class="demo-ruleForm"
        >
          <el-form-item label="备注：" prop="reason">
            <el-input
              class="dialog-input"
              type="textarea"
              :autosize="{ minRows: 6, maxRows: 6 }"
              placeholder="请输入内容"
              v-model="voidForm.reason"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="voidVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          :loading="btnLoading"
          type="primary"
          @click="
            () => {
              voidIncome('voidForm');
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="续签失败"
      class="setFailDialog"
      :append-to-body="true"
      width="400px"
      :visible.sync="setFailVisible"
    >
      <el-form
        ref="form"
        :model="fail"
        label-position="left"
        label-width="55px"
      >
        <el-form-item label="说明：">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            maxlength="500"
            show-word-limit
            v-model="fail.failCause"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="setFailVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          :loading="btnLoading"
          type="primary"
          @click="
            () => {
              setFail();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="作废"
      class="setFailDialog"
      :append-to-body="true"
      width="530px"
      :visible.sync="setDeleteVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleFormDelete"
        :rules="ruleDeleteRules"
        ref="ruleFormDelete"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="作废原因：" prop="reason">
          <el-input
            class="dialog-input"
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 6 }"
            placeholder="请输入内容"
            v-model="ruleFormDelete.reason"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="setDeleteVisible = false"
          >取 消</el-button
        >
        <el-button
          class="common-screen-btn"
          :loading="btnLoading"
          type="primary"
          @click="
            () => {
              setDelete();
            }
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <shop-order-add
      ref="shopAddDialog"
      :drawerShop="drawerShop"
      :finishedList="custList"
      :userList="userList"
      :btnLoading="btnLoading"
      @updateShop="updateShop"
      @shopAddClose="shopAddClose"
      :residuePrice="residuePrice"
    ></shop-order-add>
    <launch-income
      ref="incomeDialog"
      @orderFlowList="orderFlowList"
      @closeIncome="closeIncome"
      :visibleIncome="visibleIncome"
      :noticeList="noticeList"
    ></launch-income>
    <income-detail
      :IncomeInfo="IncomeInfo"
      @closeIncome="closeIncome"
      :visibleIncomeDetail="visibleIncomeDetail"
    ></income-detail>
    <change-order-price ref="changeOrderPrice" :shopSelect="shopSelect" @loadDetail="loadDetail"></change-order-price>
  </div>
</template>
<script>
import {
  deleteSea,
  addOrderFollow,
  updateOrder,
  orderFeedback,
  orderNext,
  saleAndRecharge,
  editOrderDetail,
  orderPriceEdit,
  deleteOrderFile,
  uploadOrderFile,
  orderNoticeList,
  orderShopList,
  orderFlowList,
  voidFlow,
  flowDetail,
  cmsShopList,
  setFailOrder,
  setDeleteOrder,
  chaneShopPrice,
  orderChangePrice
} from "../../../service/customer.js";
import { upload } from "../../../service/upload.js";
import { workLog } from "../../../service/common.js";
import {
  tableColumn,
  formatDateTimer,
  DateTransform,
  rulePrice,
  decimalNull,
  Config,
  commonLineFeed
} from "../../../utils/index.js";
import ShopOrderAdd from "../children/shopOrderAdd.vue";
import LaunchIncome from "./launchIncome.vue";
import IncomeDetail from "./incomeDetail.vue";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import CommonPicture from "../../../components/common/commonPicture.vue";
import ChangeOrderPrice from './changeOrderPrice.vue'
import { mapState } from "vuex";
export default {
  props: [
    "custDict",
    "custList",
    "platList",
    "drawer",
    "tableRow",
    "chanceList",
    "orderList",
    "adminInfo",
    "OrderInfo",
    "orderTaskList",
    "feedbackList",
    "nextStage",
    "comPeopleList",
    "userList",
    "loading",
    "fileList",
    "payTypeName",
    "residuePrice",
    'isComfirmFlow'
  ],
  components: {
    ShopOrderAdd,
    LaunchIncome,
    CommonWorkLog,
    IncomeDetail,
    CommonPicture,
    ChangeOrderPrice
  },
  data() {
    return {
      Config,
      commonLineFeed,
      formatDateTimer,
      priceRecord: [], // 调价记录
      filesList: [], // 工作记录文件
      popoVisiIndex: 0, // 店铺调价弹窗
      setFailVisible: false, // 设置续签失败二次确认弹窗
      setDeleteVisible: false, // 设置作废弹窗
      IncomeInfo: {}, // 进账详情
      visibleIncomeDetail: false, // 进账详情弹窗
      shopCheckedList: [],
      shopOldList: [],
      shopSelectOld: "",
      shopChecked: true,
      followRecordList: [], // 工作日志
      voidVisible: false, // 作废说明弹窗
      orderFlowPag: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      visibleIncome: false, // 发起收款弹窗
      isShowInput: "",
      noticeList: [], // 通知人下拉
      drawerShop: false, // 新增店铺弹窗
      editRuleForm: {
        startTime: "",
        endTime: "",
        note: "",
        agreeShopNum: "",
        agreeSubNum: "",
        conslutDayNum: "",
        price: "",
        name: "",
        introducerName: "",
        introducerPhone: "",
        introducerPrice: ""
      },
      editParams: {
        filedName: "",
        filedValue: ""
      },
      tableColumn,
      DateTransform,
      checked: false, // 是否有介绍人
      ruleFormAdd: {
        // 新增店铺数据
      }, // form提交数据
      rules: {
        // 校验规则
        orderType: [
          { required: true, message: "请选择订单类型", trigger: "change" }
        ],
        name: [
          { required: true, message: "请填写订单名称", trigger: "change" }
        ],
        toId: [{ required: true, message: "请选择指派人", trigger: "change" }],
        noticeId: [
          { required: true, message: "请选择通知人", trigger: "change" }
        ],
        price: [{ required: true, message: "请填写金额", trigger: "change" }, { validator: rulePrice, trigger: "change" }],
        startTime: [
          { required: true, message: "请选择开始时间", trigger: "change" }
        ],
        endTime: [
          { required: true, message: "请选择结束时间", trigger: "change" }
        ],
        introducerPrice: [{ validator: rulePrice, trigger: "change" }],
        agreeShopNum: [{ validator: decimalNull, trigger: "change" }],
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }],
        conslutDayNum: [{ validator: decimalNull, trigger: "change" }],
        paymentMethods: [
          { required: true, message: "请选择订单收款方式", trigger: "change" }
        ]
      },
      ruleFormEdit: {},
      editRules: {
        // 校验规则
        name: [
          { required: true, message: "请填写订单名称", trigger: "change" }
        ],
        conslutDayNum: [{ validator: decimalNull, trigger: "change" }],
        agreeShopNum: [{ validator: decimalNull, trigger: "change" }],
        agreeSubNum: [{ validator: decimalNull, trigger: "change" }]
      },
      ruleFormDelete: {
        // 作废表单
        reason: ""
      },
      ruleDeleteRules: {
        // 作废-校验规则
        reason: [
          { required: true, message: "请填写作废原因", trigger: "change" }
        ]
      },
      voidForm: {}, // 作废提交数据
      voidRules: {
        // 校验规则-作废
        reason: [
          { required: true, message: "请填写作废原因", trigger: "change" }
        ]
      },
      ruleEditRules: {
        // 校验规则
        originalMoneny: [
          { required: true, message: "请填写开始时间", trigger: "change" }
        ],
        type: [{ required: true, message: "请选择", trigger: "change" }],
        noticeId: [{ required: true, message: "请选择", trigger: "change" }],
        changeMoneny: [
          { required: true, message: "请输入金额", trigger: "change" }
        ],
        result: [{ required: true, message: "请输入金额", trigger: "change" }],
        datePicker: [
          { required: true, message: "请选择时间", trigger: "change" }
        ],
        shopIds: [{ required: true, message: "请选择店铺", trigger: "change" }]
      },
      editVisible: false,
      pictureName: "",
      pictureNameText: "",
      fileName: "",
      fileNameText: "",
      fileType: "",
      fileId: "",
      pictureId: "",
      activeName: "about", // tabs
      activeTab: "1", // tabs
      radio: "1",
      recordRadio: 1, // 跟进记录radio
      recordContent: "", // 跟进记录内容
      feedbackContent: "", // 反馈内容
      showFeedback: false,
      dialogVisible: false,
      nextPlan: {
        state: 1,
        type: 1,
        remind: null,
        planTime: "",
        followTx: false
      }, // 下次跟进计划提交数据
      ServiceFollow: {
        // 阶段任务
        checked0: false,
        checked1: true,
        checked2: true
      },
      checkPlan: false, // 下次计划按钮
      options: [
        // 筛选下拉
        { value: 5, label: "5分钟" },
        { value: 15, label: "15分钟" },
        { value: 30, label: "30分钟" },
        { value: 60, label: "一小时" }
      ],
      shopSelect: [], // 订单店铺下拉
      shopTableData: [], // 订单店铺列表
      flowTableData: [], // 订单店铺列表
      changeTableData: [], // 订单调价列表
      paginationChange: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      changeParams: {
        pageNum: 1,
        pageSize: 10
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      shopParams: {
        pageNum: 1,
        pageSize: 10
      },
      paginationFlow: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      flowParams: {
        page: 1,
        pageSize: 10
      },
      pickerOptionsStart: {},
      pickerOptionsEnd: {},
      fail: {
        failCause: ""
      },
      payType: [
        { id: 0, name: "前置收款" },
        { id: 1, name: "前置服务" },
        { id: 2, name: "免费试用" }
      ],
      isEdit: true,
      orderTypeOption: [
        {id: '2',name: '续签订单'},
        {id: '1',name: '新签订单'},
      ],
      shopPriceOne: {
        shopId: '',
        price: ''
      },
      pickerOptionsOrderStart: {},
      pickerOptionsOrderEnd: {},
      pickerOptionsStartNew: {},
      pickerOptionsEndNew: {},
      pickerOptionsPrice: {}
    };
  },
  // created() {
  //   this.orderNoticeList()
  // },
  computed: {
    ...mapState(["btnLoading"])
  },
  created() {
    if (this.$route.query.from && this.$route.query.from == 'saleAccount') {
      this.isEdit = false
    }
  },
  methods: {
    async orderChangePrice(id) { // 订单调价列表
      let changeParams = { ...this.changeParams };
      changeParams.orderId = id
      let { data } = await orderChangePrice(changeParams)
      this.changeTableData = data.list || []
      this.paginationChange.total = data.total || 0
    },
    loadDetail () { // 刷新详情
      this.workLog(this.OrderInfo.id);
      this.orderChangePrice(this.OrderInfo.id);
      this.$emit("getOrderDetail", this.tableRow);
    },
    changeDateFun(type) {
      if (type == 'start') {
        this.pickerOptionsEndNew = {
          disabledDate: (time) => {
            return time.getTime() < new Date(this.ruleFormAdd.startTime).getTime() - 1000 * 60 * 60 * 24
          }
        }
      } else {
        this.pickerOptionsStartNew = {
          disabledDate: (time) => {
            return time.getTime() > new Date(this.ruleFormAdd.endTime).getTime()
          }
        }
      }
    },
    shopPopoShow(id, price, index) {
      this.shopPriceOne = {
        shopId: id,
        price: price
      }
      this.popoVisiIndex = index
      let shopTableData = JSON.parse(JSON.stringify(this.shopTableData))
      shopTableData[index].popoVisi = true
      this.shopTableData = shopTableData
    },
    priceValid (value, callback, price) { // 单个店铺调价的校验
      let maxPrice = 0
      if (price) {
        maxPrice = this.residuePrice + Number(price)
      } else {
        maxPrice = this.residuePrice
      }
      if (!value) {
        callback();
      } else if (!/^\d{1,}(\.\d{1,2})?$/.test(value)) {
        callback(new Error("请输入数字，最多保留两位小数"));
      } else if (value > maxPrice) {
        callback(new Error("调价范围0.00 ~ " + maxPrice));
      } else {
        callback();
      }
    },
    changeOneShop() { // 修改单个店铺金额
        this.$confirm('确定修改当前订单下店铺金额吗？', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'common-deleteMessage',
            type: 'warning'
        }).then(() => {
          this.chaneShopPrice()
        }).catch(() => {
        this.$message({
            type: 'info',
            message: '已取消'
        });          
        });
    },
    changeFun() { // 一键均分
        this.$confirm('确定将订单金额均分到当前订单下店铺吗？', '确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            customClass: 'deleteMessage',
            type: 'warning'
        }).then(() => {
          this.chaneShopPrice(1)
        }).catch(() => {
        this.$message({
            type: 'info',
            message: '已取消'
        });          
        });
    },
    async chaneShopPrice(isAverage) { // 店铺调价
      if (isAverage) {
        let params = {
          isAverage: 1,
          orderId: this.OrderInfo.id
        }
        let { code } = await chaneShopPrice(params)
        if ( code == 200 ) {
          this.$message.success("店铺调价成功")
          let shopTableData = JSON.parse(JSON.stringify(this.shopTableData))
          shopTableData[this.popoVisiIndex].popoVisi = false
          this.shopTableData = shopTableData
          this.workLog(this.OrderInfo.id);
          this.orderShopList("shopTableData", this.OrderInfo.id);
          this.$emit("getOrderDetail", this.tableRow);
        }
      } else {
        this.$refs['shopPriceOne'].validate(async (valid, error) => {
          if (valid) { // 发送请求
            let params = {
              orderId: this.OrderInfo.id,
              chaneShopPriceList: [this.shopPriceOne]
            }
            let { code } = await chaneShopPrice(params)
            if ( code == 200 ) {
              this.$message.success("店铺调价成功")
              let shopTableData = JSON.parse(JSON.stringify(this.shopTableData))
              shopTableData[this.popoVisiIndex].popoVisi = false
              this.shopTableData = shopTableData
              this.workLog(this.OrderInfo.id);
              this.orderShopList("shopTableData", this.OrderInfo.id);
              this.$emit("getOrderDetail", this.tableRow);
            }
          } else {
            console.log(error)
            this.$message.error("请填写完整的信息");
          }
        })
      }
    },
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      let file = null;

      if (!items || items.length === 0) {
        this.$message.error("当前浏览器不支持本地");
        return;
      }
      // 搜索剪切板items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          file = items[i].getAsFile();
          break;
        }
      }
      if (!file) {
        // this.$message.error("粘贴内容非图片");
        return;
      }
      this.chooseFileFlow(file, "copy");
    },
    openNewWin(url) {
      window.open(url);
    },
    setDeleteShow() {
      if (this.$refs["ruleFormDelete"]) {
        this.$refs["ruleFormDelete"].resetFields();
      }
      this.setDeleteVisible = true;
    },
    async setFail() {
      // 续签失败-提交
      let params = { ...this.fail };
      params.orderId = this.OrderInfo.id;
      let res = await setFailOrder(params);
      if (res.code == 200) {
        this.tableRow.status = "setFail";
        this.workLog(this.OrderInfo.id);
        this.$message.success("设置成功");
        this.$refs.form.resetFields();
      }
      this.setFailVisible = false;
    },
    async setDelete() {
      // 作废-提交
      let ruleFormDelete = { ...this.ruleFormDelete };
      ruleFormDelete.orderId = this.OrderInfo.id;
      this.$refs["ruleFormDelete"].validate(async (valid) => {
        if (valid) {
          let res = await setDeleteOrder(ruleFormDelete);
          if (res.code == 200) {
            this.tableRow.status = "setDelete";
            this.workLog(this.OrderInfo.id);
            this.$message.success("作废成功");
          }
          this.setDeleteVisible = false;
        } else {
          return;
        }
      });
    },
    deleteShop(i) {
      let shopCheckedList = [...this.shopCheckedList];
      shopCheckedList = shopCheckedList.filter((item, index) => {
        if (item) {
          return index != i;
        }
      });
      this.shopCheckedList = shopCheckedList;
    },
    shopOldChange(e) {
      let shopOldList = [...this.shopOldList];
      let shopCheckedList = [...this.shopCheckedList];
      let isExistence = false;
      shopCheckedList.map((item) => {
        if (item.id == e) {
          isExistence = true;
        }
      });
      if (isExistence) {
        return this.$message.error("已选择");
      }
      if (e) {
        shopCheckedList.push(
          ...shopOldList.filter((item) => {
            return item.id == e;
          })
        );
      }
      this.shopCheckedList = shopCheckedList;
    },
    async cmsShopList() {
      let resData = (
        await cmsShopList({ customerId: this.OrderInfo.customerId })
      ).data;
      this.shopOldList = resData;
    },
    async flowDetail(id, type) {
      // 进账流水详情
      let resData = (await flowDetail({ flowId: id })).data;
      if (type == "edit") {
        this.$refs.incomeDialog.getFormData(
          this.OrderInfo.id,
          "edit",
          resData,
          this.tableRow.orderNo
        );
        this.visibleIncome = true;
        this.$refs.incomeDialog.incomeDetail(
          this.OrderInfo.id,
          "",
          "",
          "click"
        );
      } else {
        this.IncomeInfo = resData;
        this.visibleIncomeDetail = true;
      }
    },
    async orderFlowList(id) {
      // 进账列表
      let flowParams = { ...this.flowParams };
      flowParams.orderId = id;
      let resData = (await orderFlowList(flowParams)).data;
      this.flowTableData = resData.list;
      this.paginationFlow.total = resData.total;
      this.workLog(id);
    },
    incomeShow() {
      // 发起收款弹窗
      let OrderInfo = { ...this.OrderInfo };
      this.$refs.incomeDialog.getFormData(
        OrderInfo.id,
        "",
        "",
        this.tableRow.orderNo
      );
      this.visibleIncome = true;
    },
    updateShop() {
      this.orderShopList("shopTableData", this.OrderInfo.id);
      this.loadDetail(this.OrderInfo.id);
      this.workLog(this.OrderInfo.id);
    },
    async priceRecordFun(id) {
      // 调价记录
      let { data } = await workLog({
        id: id,
        type: 1,
        templateType: "orderAdjustment"
      });
      if (data && data.length) {
        data.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = [];
          }
        });
      }
      this.priceRecord = data;
    },
    async workLog(id) {
      // 工作日志
      let resData = (await workLog({ id: id, type: 1 })).data;
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = [];
          }
        });
      }
      this.followRecordList = resData;
    },
    pageFunChange(e, type) { // 分页
      if (type == "pageSize") {
        this.changeParams.pageSize = e;
      } else {
        this.changeParams.pageNum = e;
      }
      this.orderChangePrice(this.OrderInfo.id);
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.shopParams.pageSize = e;
      } else {
        this.shopParams.pageNum = e;
      }
      this.orderShopList("shopTableData", this.OrderInfo.id);
    },
    pageFunFlow(e, type) {
      // 分页
      if (type == "pageSize") {
        this.flowParams.pageSize = e;
      } else {
        this.flowParams.page = e;
      }
      this.orderFlowList(this.OrderInfo.id);
    },
    async orderShopList(type, id) {
      // 订单店铺-列表/下拉
      let shopParams = { ...this.shopParams };
      if (type == "shopSelect") {
        shopParams.pageNum = -1;
      }
      shopParams.orderId = id;
      let resData = (await orderShopList(shopParams)).data;
      if (type == "shopSelect") {
        let shopList = [];
        this[type] = resData;
        resData.map((item) => {
          shopList.push({ id: item.id, shop_name: item.shopName });
        });
        this.shopCheckedList = shopList;
      } else {
        resData.records.map((item) => {
          item.popoVisi = false
        })
        this[type] = resData.records;
        this.pagination.total = resData.total;
      }
    },
    downLoad(url) { // 下载文件
      window.location.href = url;
    },
    addShop(orderInfo) {
      //新增店铺
      this.drawerShop = true;
      if (orderInfo) {
        this.$refs["shopAddDialog"].getDataInfo("", orderInfo);
      }
    },
    againOrder() {
      // 再来一单
      this.ruleFormAdd = {
        custName: this.OrderInfo.customerName
      };
      this.orderShopList("shopSelect", this.OrderInfo.id);
      this.cmsShopList();
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
      this.shopChecked = true;
      this.checked = false;
      this.dialogVisible = true;
    },
    editCancelBtn(name) {
      // 取消编辑
      setTimeout(() => {
        if (this.isShowInput == name) {
          this.editParams = {
            filedName: "",
            filedValue: ""
          };
          this.isShowInput = "";
        }
      }, 500);
    },
    async orderNoticeList() {
      let resData = (await orderNoticeList()).data;
      this.noticeList = resData;
    },
    changePrice(e) {
      this.ruleFormEdit.result = Number(e) + this.ruleFormEdit.originalMoneny;
    },
    download(url) {
      // 下载
      window.location.href = url;
    },
    deleteFileFlow(id) {
      let filesList = [...this.filesList];
      this.filesList = filesList.filter((item) => {
        return item.fileId != id;
      });
    },
    async deleteFile(item) {
      this.$emit("loadFun", true);
      let params = {
        orderId: this.tableRow.id,
        filedId: item.id,
        filedUrl: item.url,
        fileName: item.name
      };
      await deleteOrderFile(params);
      this.$message.success("删除成功");
      this.$emit("getOrderDetail", this.tableRow);
    },
    async uploadFile(e) {
      // 上传附件

      let file = e.target.files[0];
      let fileName = file.name;
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      this.$emit("loadFun", true);
      let resData = (await upload({ file: file })).data;
      await uploadOrderFile({
        orderId: this.tableRow.id,
        filedId: resData.id,
        fileName: fileName,
        filedUrl: resData.filename
      });
      this.$message.success("上传成功");
      e.target.value = "";
      this.$emit("getOrderDetail", this.tableRow);
    },
    voidIncome(formName) {
      // 作废发起收款流水
      let voidForm = { ...this.voidForm };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$vuex.commit("btnLoading", true);
          await voidFlow(voidForm);
          this.voidVisible = false;
          this.orderFlowList(this.OrderInfo.id);
          this.workLog(this.OrderInfo.id);
          this.$vuex.commit("btnLoading", false);
          this.$message.success("作废成功");
        } else {
          return false;
        }
      });
    },
    voidShow(id) {
      // 作废弹窗
      this.voidForm.flowId = id;
      this.voidVisible = true;
    },
    orderPriceEdit(formName) {
      let ruleFormEdit = { ...this.ruleFormEdit };
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.$emit("loadFun", true);
          if (ruleFormEdit && ruleFormEdit.noticeId) {
            ruleFormEdit.noticeId = ruleFormEdit.noticeId.join(",");
          }
          if (ruleFormEdit && ruleFormEdit.shopIds) {
            ruleFormEdit.shopIds = ruleFormEdit.shopIds.join(",");
          } else {
            delete ruleFormEdit.shopIds;
          }
          if (ruleFormEdit.datePicker && ruleFormEdit.datePicker.length) {
            ruleFormEdit.startTime = DateTransform(ruleFormEdit.datePicker[0]);
            ruleFormEdit.endTime = DateTransform(ruleFormEdit.datePicker[1]);
          }
          await orderPriceEdit(ruleFormEdit);
          this.editVisible = false;
          this.orderShopList("shopTableData", this.OrderInfo.id);
          this.$emit("getOrderDetail", this.tableRow);
          this.$message.success("调价成功");
        } else {
          return false;
        }
      });
    },
    editOrderDetail(name) {
      // 确认-提交修改
      let params = {
        orderId: this.tableRow.id,
        customerId: this.tableRow.customer_id,
        filedName: name,
        filedValue: this.editRuleForm[name]
      };
      if (this.editParams.filedName) {
        this.$emit("loadFun", true);
        this.$refs["editRuleForm"].validateField(name, async (error) => {
          if (!error) {
            await editOrderDetail(params);
            this.$emit("getOrderDetail", this.tableRow);
            this.editParams = {
              filedName: "",
              filedValue: ""
            };
          } else {
            this.$emit("loadFun", false);
            return false;
          }
        });
      }
    },
    editShow() { // 显示订单调价
      this.orderShopList("shopSelect", this.OrderInfo.id);
      if (this.isComfirmFlow == 1) {
        this.$refs.changeOrderPrice.getDataInfo({orderNo: this.tableRow.orderNo, ...this.OrderInfo})
      } else {
        this.priceRecordFun(this.OrderInfo.id);
        this.pickerOptionsPrice = {
          disabledDate: (time) => {
            return time.getTime() > new Date(this.OrderInfo.endTime).getTime() || time.getTime() < new Date(this.OrderInfo.startTime).getTime()
          }
        }
        let ruleFormEdit = {
          orderId: this.tableRow.id,
          filedName: "price",
          originalMoneny: this.OrderInfo.price,
          result: this.OrderInfo.price + 1,
          type: 0,
          noticeId:
          this.noticeList && this.noticeList[0] ? [this.noticeList[0].id] : [],
          agreeSubNum: 1,
          changeMoneny: 1,
          shopIds: []
        };
        this.ruleFormEdit = ruleFormEdit;
        this.editParams = {
          filedName: "",
          filedValue: ""
        };
        if (this.$refs["ruleFormEdit"]) {
          this.$refs["ruleFormEdit"].resetFields();
        }
        this.editVisible = true;
      }
    },
    async chooseFileFlow(e, type) {
      // 订单工作日志文件上传
      let file;
      if (type != "copy") {
        file = e.target.files[0];
      } else {
        file = e;
      }
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        if (type != "copy") {
          e.target.value = "";
        }
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        if (type != "copy") {
          e.target.value = "";
        }
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      this.$vuex.commit("btnLoading", true);
      let resData = (await upload({ file: file })).data;
      this.$vuex.commit("btnLoading", false);
      let fileItem = {
        fileType: Config.fileType.imgType.includes(
          file.name.split(".")[file.name.split(".").length - 1]
        )
          ? "imgText"
          : "file",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename
      };
      // if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
      //   fileItem.fileType = "img";
      // }
      this.filesList.push(fileItem);
      if (type != "copy") {
        e.target.value = "";
      }
    },
    async chooseFile(e, type) {
      // 反馈上传文件
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        this.$message.error("文件大小不能超过50M");
      }
      if (type == "img") {
        if (
          format != "bmp" &&
          format != "jpg" &&
          format != "png" &&
          format != "gif" &&
          format != "jpeg"
        ) {
          this.$message.error("仅支持bmp、png、jpg、jpeg、gif格式文件");
          e.target.value = "";
          return;
        }
      }
      if (type == "xlsx") {
        if (
          format != "doc" &&
          format != "docx" &&
          format != "xls" &&
          format != "xlsx"
        ) {
          this.$message.error("仅支持doc、docx、xls、xlsx格式文件");
          e.target.value = "";
          return;
        }
      }
      let resData = (await upload({ file: file })).data;
      if (type == "img") {
        this.pictureName = resData.filename;
        this.pictureNameText = file.name;
        this.pictureId = resData.id;
      } else {
        this.fileName = resData.filename;
        this.fileNameText = file.name;
        this.fileId = resData.id;
      }
      this.fileType = type;
      e.target.value = "";
    },
    subNewOrderForm(formName) {
      // 再来一单
      let ruleFormAdd = { ...this.ruleFormAdd };
      let shopCheckedList = [...this.shopCheckedList];
      this.$vuex.commit("btnLoading", true);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleFormAdd.custName) {
            delete ruleFormAdd.custName;
          }
          if (this.shopChecked && ruleFormAdd.orderType =='2') {
            let ids = [];
            let names = [];
            shopCheckedList.map((item) => {
              ids.push(item.id);
              names.push(item.shop_name);
            });
            ruleFormAdd.shopIds = ids.join(",");
            ruleFormAdd.shopNames = names.join(",");
          } else {
            delete ruleFormAdd.shopNames;
            delete ruleFormAdd.shopIds;
          }
          if (!this.checked) {
            if (ruleFormAdd.introducerName) {
              delete ruleFormAdd.introducerName;
            }
            if (ruleFormAdd.introducerPhone) {
              delete ruleFormAdd.introducerPhone;
            }
            if (ruleFormAdd.introducerPrice) {
              delete ruleFormAdd.introducerPrice;
            }
          }
          ruleFormAdd.shopId = this.OrderInfo.shopId;
          ruleFormAdd.customerId = this.OrderInfo.customerId;
          ruleFormAdd.companyId = this.OrderInfo.companyId;
          ruleFormAdd.id = this.OrderInfo.id;
          let res = await saleAndRecharge(ruleFormAdd);
          if (res.code == 200) {
            // this.$emit("RenewalParams", res.data);
            this.popoVisi = false
            this.$emit("handleClose");
            this.dialogVisible = false;
          }
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    closeIncome(isShow) {
      // 发起收款关闭弹窗
      this.visibleIncome = isShow;
      this.visibleIncomeDetail = isShow;
    },
    async orderNext() {
      // 进入下一阶段
      let params = {
        step: this.OrderInfo.orderStep,
        oid: this.tableRow.id
      };
      await orderNext(params);
      this.$emit("getOrderDetail", this.tableRow);
      this.$message.success("提交成功");
    },
    async addFeedback() {
      // 提交反馈
      let params = {
        orderId: this.tableRow.id,
        content: this.feedbackContent,
        pictureId: this.pictureId,
        picture: this.pictureName,
        fileId: this.fileId,
        cmsFile: this.fileName
      };
      await orderFeedback(params);
      this.$message.success("提交成功");
      this.feedbackContent = "";
      this.pictureId = "";
      this.pictureName = "";
      this.fileId = "";
      this.fileName = "";
      this.$emit("getOrderDetail", this.tableRow);
    },
    getNowStage(row) {
      // 父组件传默认值
      let orderName = row.orderName || "";
      let newArr = [];
      if (orderName && orderName.indexOf("-") > -1) {
        newArr = orderName.split("-");
        if (!isNaN(Number(newArr[newArr.length - 1]))) {
          newArr = [...newArr, Number(newArr[newArr.length - 1]) + 1];
        } else {
          newArr = [...newArr, "1"];
        }
      } else {
        newArr = [orderName, "1"];
      }
      this.ruleFormAdd = {
        name: newArr.join("-"),
        custName: row.customerName || row.customer_name,
        chanceId: row.id
      };
      this.pickerOptionsStart = {
        // 开始时间禁用
        disabledDate: (time) => {
          return time.getTime() > new Date(this.OrderInfo.endTime).getTime();
        }
      };
      this.pickerOptionsEnd = {
        // 结束时间禁用
        disabledDate: (time) => {
          return time.getTime() < new Date(this.OrderInfo.startTime).getTime();
        }
      };
    },
    async addOrderFollow() {
      // 跟进发布按钮
      let filesList = [...this.filesList];
      let filesUrl = [];
      let filesId = [];
      let filesName = [];
      filesList.map((item) => {
        filesUrl.push(item.fileUrl);
        filesId.push(item.fileId);
        filesName.push(item.fileName);
      });
      let params = {
        content: this.recordContent,
        orderId: this.OrderInfo.id,
        fileIds: filesId.join(",")
        // fileUrl: filesUrl.join(','),
        // fileNames: filesName.join(',')
      };
      await addOrderFollow(params);
      this.filesList = [];
      // this.$emit("chanceDetail", this.tableRow);
      this.recordContent = "";
      this.$message.success("添加成功");
      this.$emit("getOrderDetail", this.tableRow);
    },
    editBtn(name, value) {
      this.$refs["editRuleForm"].validate(async (valid) => {
        if (valid) {
          // this.promiseFun(name, value)
          new Promise((resolve) => {
            this.editParams = {
              filedName: name,
              filedValue: value
            };
            this.editRuleForm[name] = value;
            resolve();
          })
            .then(() => {
              if (this.$refs[name]) {
                this.$refs[name].focus();
              }
            })
            .then(() => {
              this.isShowInput = name;
            });
        } else {
          return;
        }
      });
    },
    async orderStateFun(value, id) {
      let params = {
        orderStageState: value ? "1" : "0"
      };
      await updateOrder(params, id);
      this.$emit("getOrderDetail", this.tableRow);
      this.$message.success("更新成功");
    },
    async deleteSea() {
      // 删除客户
      await deleteSea({}, this.tableRow.id);
      this.$message.success("删除成功");
      this.handleClose();
    },
    handleClose(done) {
      this.popoVisi = false
      this.$emit("handleClose", done);
    },
    handleClick() {
      console.log(this.activeName);
    },
    shopAddClose() {
      this.drawerShop = false;
    },
    loadFun(value) {
      this.$emit("loadFun", value);
    },
    orderChange(val){
      if(val == '2'){
        this.pickerOptionsOrderStart = {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.OrderInfo.endTime).getTime() + 1000 * 60 * 60 * 24
        }
        },
        this.pickerOptionsOrderEnd = {
          disabledDate: (time) => {
            return time.getTime() < new Date(this.ruleFormAdd.startTime).getTime()
          }
        }
      }else { //新签
        this.pickerOptionsOrderStart = {}
        this.pickerOptionsOrderEnd = {}
      }
      
    }
  }
};
</script>
<style lang="less">
.shopPopoTitle {
  font-size: 14px;
  color: #333;
  padding: 0 0 12px;
  display: block;
}
.price-input {
  .el-input__suffix {
    right: 0;
    top: -1px;
  }
  .el-input__inner {
    border: 1px solid #1890ff;
    height: 40px;
    line-height: 40px;
  }
}
.input-after-btn {
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  margin-top: 2px;
  vertical-align: top;
  height: 38px;
  line-height: 38px;
  color: #1890ff;
  width: 60px;
  text-align: center;
  border: 1px solid #1890ff;
}
.deleteMessage {
  width: 400px !important;
  height: auto !important;
}
</style>
<style lang="less" scoped>
.form-left, .form-right {
  width: 49%;
  display: inline-block;
  vertical-align: top;
}
.grey-line {
  height: 1px;
  width: 120%;
  margin: 0 0 0 -10%;
  background: #eee;
}
.record-container {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 16px;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  .recordItem-content {
    padding-left: 20px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 16px;
    .content-item {
      font-size: 14px;
      line-height: 24px;
      color: #101010;
    }
  }
  .recordItem-title {
    display: flex;
    justify-content: space-between;
    .title-left {
      color: #1890ff;
      font-size: 14px;
      display: flex;
      align-items: center;
      span:nth-of-type(1) {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 4px solid #1890ff;
        box-sizing: border-box;
        margin-right: 6px;
      }
    }
  }
}
.setFailDialog {
  /deep/.el-dialog__header {
    padding: 10px 20px;
    .el-dialog__title {
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: 14px;
    }
  }
  /deep/.el-dialog__body {
    padding: 20px 40px;
  }
}
.orderShop-old {
  width: 320px;
  height: 160px;
  border-radius: 4px;
  border: #dcdfe6 solid 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 0 4px 0;
  .oldItem {
    display: inline-block;
    padding: 0 8px 0 10px;
    line-height: 30px;
    color: #fff;
    background-color: #1890ff;
    border-radius: 4px;
    margin: 2px 0 0 8px;
    .close {
      color: #fff;
      margin-left: 8px;
      cursor: pointer;
      font-size: 18px;
    }
  }
}
.confirmed {
  color: #ccc;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
  width: 80px;
}
.unconfirmed {
  color: #ee5b5b;
  border: 1px solid #ee5b5b;
  border-radius: 4px;
  padding: 2px 4px;
  text-align: center;
  width: 80px;
}
.overtime-container {
  margin-left: 85px;
  border-left: #1890ff solid 4px;
  .dialog-input {
    width: 261px !important;
  }
}
/deep/.el-radio {
  line-height: 38px;
}
.orderDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .feedbackImg {
    width: 80px;
    margin-right: 20px;
    cursor: pointer;
  }
  .fileNameText {
    position: absolute;
    left: 0;
    top: 50px;
    font-size: 12px;
    color: #afafaf;
    word-break: break-all;
  }
  .steps-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    .steps-left {
      font-size: 16px;
      color: #000000a6;
    }
    .steps-center {
      width: 480px;
      margin: 16px 48px;
      font-size: 12px;
    }
  }
  .SubmitPlan-container {
    margin-top: 24px;
    .SubmitPlan-item,
    .feedback-item {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px dashed #797979;
      .item-row {
        display: flex;
        align-items: center;
        color: #999;
        font-size: 12px;
        .item-btn {
          height: 28px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
          border-radius: 2px;
          background: #1890ff;
          color: #fff;
          padding: 0 6px;
          margin: 10px 10px 14px;
        }
        .item-user {
          margin: 0 10px;
        }
      }
    }
    .feedback-item {
      margin-top: 16px;
      padding-top: 24px;
      border-bottom: none;
      border-top: 1px dashed #797979;
    }
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .fileImg {
      width: 80px;
      margin-top: 8px;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .Btn-container {
        width: 100px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #1890ff;
        color: #1890ff;
        font-size: 14px;
        background: #fff;
        text-align: center;
        border-radius: 4px;
        margin-top: 16px;
        display: inline-block;
        cursor: pointer;
      }
      .left-top {
        display: flex;
        justify-content: space-between;
        .rightBtn-group {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .common-screen-btn {
            width: 100px;
            margin-bottom: 16px;
            height: 34px;
          }
        }
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #ebeef5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #ebeef5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .rightTop {
        border-bottom: 1px #eaeaeb solid;
        padding: 24px;
        font-size: 14px;
        color: #101010;
        .visitIcon {
          width: 14px;
          vertical-align: middle;
          margin-right: 8px;
        }
        .notContent {
          margin-top: 24px;
        }
      }
      /deep/ .el-tabs__content {
        padding-left: 16px;
      }
      .record-item {
        margin-top: 16px;
        border-top: 1px #eaeaeb dashed;
        padding-top: 16px;
        .item-content {
          margin-left: 22px;
          .itemBtn {
            display: inline-block;
            height: 32px;
            line-height: 32px;
            padding: 0 14px;
            border: 1px solid #1890ff;
            border-radius: 4px;
            font-size: 14px;
            color: #101010;
            margin: 16px 16px 8px 0;
          }
          .item-detail {
            color: #333;
            margin-top: 8px;
            vertical-align: middle;
            .visitIcon {
              width: 14px;
              margin-left: 8px;
            }
          }
        }
        .item-top {
          padding-right: 16px;
          .item-icon {
            display: inline-block;
            width: 4px;
            height: 4px;
            background-color: #fff;
            border: 4px #1890ff solid;
            border-radius: 50%;
            margin-right: 8px;
          }
          .item-role {
            color: #1890ff;
          }
          .item-date {
            float: right;
            color: #999;
          }
        }
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        font-size: 18px;
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #f98787;
          color: #f98787;
          margin-left: 16px;
        }
      }

      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .dateInput {
        width: 225px;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99%
            center #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        .editColor {
          color: #1890ff;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
      .modifyPrice {
        display: inline-block;
        // width: 90px;
        vertical-align: middle;
        padding: 0 26px 0 6px;
        height: 28px;
        line-height: 28px;
        // border: #1890ff 1px solid;
        // border-radius: 4px;
        margin-left: 8px;
        position: relative;
        &::after {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -66px;
          content: "订单调价";
          color: #1890ff;
          cursor: pointer;
        }
      }
      .modifyPrice2 {
        display: inline-block;
        vertical-align: middle;
        padding: 0 26px 0 6px;
        height: 28px;
        line-height: 28px;
        margin-left: 8px;
        position: relative;
      }
    }
    .detail-address {
      margin: 16px 0;
      color: #000000a6;
    }
    .about-content {
      padding-bottom: 18px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
    }
    .data-content {
      .detail-content {
        div,
        span {
          font-size: 12px;
        }
        div {
          width: 45%;
          height: 32px;
          line-height: 32px;
        }
      }
      .data-title {
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        color: #000000a6;
        border-bottom: 1px dashed #ccc;
        margin-top: 16px;
      }
    }
    .ServiceFollow-content {
      border: 1px solid #ebeef5;
      margin-top: 16px;
      padding: 36px 0;
      border-radius: 4px;
      .ServiceFollow-item {
        padding-left: 20px;
        margin-bottom: 20px;
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .feedback {
        color: #1890ff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      }
      .feedback-content {
        padding: 20px;
        margin-top: 16px;
        text-align: left;
        .upload-userIcon {
          width: 88px;
          height: 32px;
          padding: 0;
          position: relative;
          display: inline-block;
          margin: 24px 20px 0 20px;
        }
        .upload-commit {
          height: 60px;
          .com-btns {
            float: right;
            margin-top: 24px;
          }
        }
      }
    }
  }

  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
}
/deep/.el-step__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: -25px;
}
/deep/.el-step__icon-inner {
  font-size: 14px !important;
  width: 12px !important;
  margin-left: -2px;
}
/deep/.el-step__icon.is-icon {
  width: 10px !important;
}
.dialog-input {
  width: 320px !important;
}
.width-35 {
  width: 35%;
}
.formItem-right {
  margin-left: 50px;
}
.introducer {
  position: relative;
  padding: 24px 0 0;
  &::before {
    background-color: #f5f5f5;
    position: absolute;
    display: block;
    content: " ";
    height: 1px;
    width: 120%;
    left: -10%;
    top: 0;
  }
}
.label1 {
  box-sizing: border-box;
  display: inline-block;
  color: #706eff;
  border: 1px solid #706eff;
  border-radius: 4px;
  background-color: #f4f4ff;
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 16px;
}
.label2 {
  // height: 33px;
  display: inline-block;
  color: #ce358f;
  border: 1px solid #ce358f;
  border-radius: 4px;
  background-color: #fbeff6;
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 16px;
}
.label3 {
  // height: 33px;
  display: inline-block;
  color: #f09009;
  border: 1px solid #f09009;
  border-radius: 4px;
  background-color: #fef6ec;
  font-size: 12px;
  padding: 2px 8px;
  margin-left: 16px;
}
</style>
